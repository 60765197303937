import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = (props) => (
    <div className='container text-center' style={{opacity:'.2'}}>
        <ContentLoader width={900} height={450} speed={2} className="w-100" viewBox="0 0 900 450" backgroundColor="#ddd" foregroundColor="#a6b5ce">
            <rect x="395" y="50" rx="10" ry="10" width="110" height="15" />
            <rect x="200" y="90" rx="30" ry="30" width="500" height="40" />
            <rect x="100" y="150" rx="10" ry="10" width="700" height="15" />
            <rect x="75" y="180" rx="10" ry="10" width="750" height="15" />
            <rect x="100" y="210" rx="10" ry="10" width="700" height="15" />
            <rect x="75" y="240" rx="10" ry="10" width="750" height="15" />
            <rect x="300" y="270" rx="10" ry="10" width="300" height="15" />
            <rect x="375" y="310" rx="10" ry="10" width="150" height="45" />
        </ContentLoader>
    </div>
)

export default MyLoader
import React, { useEffect, useState } from 'react'
import '../asset/cuscomcss/home.css'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom'
import axios from 'axios';
import helper from '../Helper';
import Servicebox from './Servicebox';
import moment from 'moment';
import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import Rellax from "rellax";

import AboutLoader from '../loader/About';
import ServiceLoader from '../loader/Service';
import AwardLoader from '../loader/Service';
import PhotosLoader from '../loader/Gallery';
import BlogLoader from '../loader/Blog';
import Meta from './Meta';

export default function Home() {
  Fancybox.bind('[data-fancybox="awards"]', {});
  Fancybox.bind('[data-fancybox="video"]', {});
  Fancybox.bind('[data-fancybox="gallery"]', {});
  const [about, setAbout] = useState('');
  const [services, setServices] = useState([]);
  const [awarddata, setAwardData] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [videos, setVideos] = useState([]);
  const [corevalues, setCoreValues] = useState([]);

  const [serviceheading, setServiceHeading] = useState('');
  const [whyweheading, setWhyWeAreHeading] = useState('');
  const [awardheading, setAwardHeading] = useState('');
  const [eventheading, setEventHeading] = useState('');
  const [blogheading, setBlogHeading] = useState('');
  const [testimonialheading, setTestimonialHeading] = useState('');

  const [aboutloader, setAboutloader] = useState(true);
  const [serviceloader, setServiceloader] = useState(true);
  const [awardloader, setAwardloader] = useState(true);
  const [photosloader, setPhotosloader] = useState(true);
  const [blogloader, setBlogloader] = useState(true);

  const fetchAboutApi = async () => {
    const AboutApI = process.env.REACT_APP_API + 'getcmsdata/13';
    axios.get(AboutApI)
      .then((res) => {
        setAbout(res.data.result); setAboutloader(false);
      });
  }

  const fetchServiceApi = async () => {
    const ServiceApI = process.env.REACT_APP_API + 'services?type=home';
    axios.get(ServiceApI)
      .then((res) => {
        setServices(res.data.result.data); setServiceloader(false);
      });
  }

  const fetchAwardsApi = async () => {
    const AwardApI = process.env.REACT_APP_API + 'awards';
    axios.get(AwardApI)
      .then((res) => {
        setAwardData(res.data.result.data); setAwardloader(false);
      });
  }

  const fetchPhotoApi = async () => {
    const PhotosAPI = process.env.REACT_APP_API + 'gallery?type=photos';
    axios.get(PhotosAPI)
      .then((res) => {
        setPhotos(res.data.result.data); setPhotosloader(false);
      });
  }

  const fetchVideoApi = async () => {
    const VideoAPI = process.env.REACT_APP_API + 'gallery?type=videos';
    axios.get(VideoAPI)
      .then((res) => {
        setVideos(res.data.result.data);
        setTimeout(() => {
          initYouTubeVideos();
        }, 500);
      });
  }

  const fetchTestimonialApi = async () => {
    const TestimonialApI = process.env.REACT_APP_API + 'testimonials?type=home';
    axios.get(TestimonialApI)
      .then((res) => {
        setTestimonials(res.data.result.data);
      });
  }

  const fetchCoreValueApi = async () => {
    const CorevaluesApI = process.env.REACT_APP_API + 'corevalues';
    axios.get(CorevaluesApI)
      .then((res) => {
        setCoreValues(res.data.result);
        setTimeout(() => {
          new Rellax('.rellax', { center: true });
        }, 2000);
      });
  }

  const fetchBlogApi = async () => {
    const BlogApi = process.env.REACT_APP_API + 'blogs?type=topblogs';
    axios.get(BlogApi)
      .then((res) => {
        setBlogs(res.data.result.data);
        setBlogloader(false);
      });
  }

  const fetchServiceHeadingApi = async () => {
    const ServiceHeadingAPI = process.env.REACT_APP_API + 'getcmsdata/14';
    axios.get(ServiceHeadingAPI)
      .then((res) => {
        setServiceHeading(res.data.result); 
      });
  }

  const fetchBlogHeadingApi = async () => {
    const BlogHeadingAPI = process.env.REACT_APP_API + 'getcmsdata/18';
    axios.get(BlogHeadingAPI)
      .then((res) => {
        setBlogHeading(res.data.result); 
      });
  }

  const fetchTestimonialHeadingApi = async () => {
    const TestimonialHeadingAPI = process.env.REACT_APP_API + 'getcmsdata/17';
    axios.get(TestimonialHeadingAPI)
      .then((res) => {
        setTestimonialHeading(res.data.result);
      });
  }

  const fetchAwardHeadingApi = async () => {
    const AwardHeadingAPI = process.env.REACT_APP_API + 'getcmsdata/2';
    axios.get(AwardHeadingAPI)
      .then((res) => {
        setAwardHeading(res.data.result);
      });
  }

  const fetchEventHeadingApi = async () => {
    
    const EventHeadingAPI = process.env.REACT_APP_API + 'getcmsdata/5';
    axios.get(EventHeadingAPI)
      .then((res) => {
        setEventHeading(res.data.result);
      });
  }

  const fetchWhyWeAreHeadingApi = async () => {    
    const WhyWeAreHeadingAPI = process.env.REACT_APP_API + 'getcmsdata/15';
    axios.get(WhyWeAreHeadingAPI)
      .then((res) => {
        setWhyWeAreHeading(res.data.result);
      });
  }

  useEffect(() => {
   
    /**** Heading API ****/
    fetchServiceHeadingApi();
    fetchBlogHeadingApi();
    fetchTestimonialHeadingApi();
    fetchAwardHeadingApi();
    fetchEventHeadingApi();
    fetchWhyWeAreHeadingApi();

    /**** Date API ****/
    fetchAboutApi();
    fetchServiceApi();
    fetchAwardsApi();
    fetchPhotoApi();
    fetchVideoApi();
    fetchBlogApi();
    fetchTestimonialApi();
    fetchCoreValueApi();
    // eslint-disable-next-line
  }, []);

  function labnolIframe(div) {
    var iframe = document.createElement('iframe');
    iframe.setAttribute(
      'src',
      'https://www.youtube.com/embed/' + div.dataset.id + '?autoplay=1&rel=0'
    );
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('allowfullscreen', '1');
    iframe.setAttribute(
      'allow',
      'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
    );
    div.parentNode.replaceChild(iframe, div);
  }

  function initYouTubeVideos() {
    var playerElements = document.getElementsByClassName('youtube-player');
    for (var n = 0; n < playerElements.length; n++) {
      var videoId = playerElements[n].dataset.id;
      var div = document.createElement('div');
      div.setAttribute('data-id', videoId);
      var thumbNode = document.createElement('img');
      thumbNode.src = '//i.ytimg.com/vi/ID/hqdefault.jpg'.replace(
        'ID',
        videoId
      );
      thumbNode.alt = "Youtube Voideo";
      thumbNode.loading = "lazy";
      div.appendChild(thumbNode);
      var playButton = document.createElement('div');
      playButton.setAttribute('class', 'play');
      div.appendChild(playButton);
      div.onclick = function () {
        labnolIframe(this);
      };
      playerElements[n].appendChild(div);
    }
  }

  return (
    <main>
      <Meta id={1}/>
      <section className="Slider Home">
        <div id="HomeBanner" className="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-interval="5000">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <picture>
                <source srcSet={require('../asset/img/banner-bg1.webp')} type="image/webp" />
                <img loading="lazy" src={require('../asset/img/banner-bg1.jpg')} alt="banner" width="1600" height="650" />
              </picture>
              <div className="carousel-caption mt-5">
                <div className="container mt-5 wow fadeIn">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-12 text-center order-md-last">
                      <img src={require('../asset/img/logo1.svg').default} className="mb-3" height="600" width="600" alt="Purple Unicorn" />
                      <h2 className="title text-white">"Every function should be perfect"</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button type="button" className="h1 ArrowDown BottomSec" aria-label="Sections"><span></span></button>
      </section>
      {
        aboutloader ?
          <AboutLoader />
          :
          about.length > 0 ?
            ''
            :
            <section className="Sec1" data-text="Welcome to">
              <div className="container">
                <div className="row align-items-center justify-content-center">
                  <div className="col-12 col-md-9 col-lg-8 text-center wow fadeInUp">
                    <span>{about.title}</span>
                    {
                      about.heading ?
                        <h1 className="Heading fs-xl mb-4">{helper.seprateArray(about.heading, 3)[0] ?? ''}<span className="d-block">{helper.seprateArray(about.heading, 3)[1] ?? ''}</span> {helper.seprateArray(about.heading, 3)[2] ?? ''}</h1>

                        :
                        ''
                    }
                    <div dangerouslySetInnerHTML={{ __html: about.description }} />
                    <Link to="/about" className="font1"><u>Read More</u></Link>
                  </div>
                </div>
              </div>
              <img src={require('../asset/img/wedding_planner.svg').default} className="bg-img w-100" height="500" width="1600" alt="wedding planner" />
              <button type="button" className="h1 ArrowDown BottomSec" aria-label="Sections"><span></span></button>
            </section>
      }

      {
        serviceloader ?
          <ServiceLoader />
          :
          services.length ?
            <section className="Sec2" data-text="All Services">
              <div className="container">
                <div className="row align-items-center justify-content-center">
                  <div className="col-12 col-md-9 col-lg-8 text-center wow fadeInUp">
                    <span>{serviceheading.title}</span>
                    <h2 className="Heading h1 fs-xl mb-4">{serviceheading.heading}</h2>
                    <div dangerouslySetInnerHTML={{ __html: serviceheading.description }} />
                  </div>
                </div>
                <div className="row justify-content-center">
                  {
                    services.map((service, sr) =>
                      <Servicebox service={service} key={sr} />
                    )
                  }
                </div>
                <div className="text-center"><Link to={"/services"} className="btn btn-thm"><span>View All Services</span></Link></div>
              </div>
              <button type="button" className="h1 ArrowDown BottomSec" aria-label="Sections"><span></span></button>
            </section>
            : ''
      }

      {
        corevalues.length ?
          <section className="Sec3" data-text="We are Best">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 position-relative">
                  <div className="text-center mb-5">
                    <div className="row justify-content-center">
                      <div className="col-lg-5 col-md-8">
                        <h2 className="Heading h1 fs-xl mb-4 wow fadeInDown">{whyweheading.title} <span className="d-block">{whyweheading.heading}</span></h2>
                        <div className='mb-5' dangerouslySetInnerHTML={{ __html: whyweheading.description }} />
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    {
                      corevalues.map((corevalue, cv) =>
                        <div className="TeamImg rellax" style={{ cursor: 'auto' }} key={cv} data-rellax-speed={cv + 1 === corevalues.length ? '4' : ((cv + 1) % 2 ? '1' : '-2')}>
                          {
                            helper.checkimagetype(corevalue.image) ?
                              <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'core/' + corevalue.image} alt={corevalue.title} width="90" height="90" />
                              :
                              <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'core/webp/' + corevalue.image + '.webp'} alt={corevalue.title} width="90" height="90" />

                          }
                          <h3 className="h4 mt-3">{corevalue.title}</h3>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
            <button type="button" className="h1 ArrowDown BottomSec" aria-label="Sections"><span></span></button>
          </section>
          : ''
      }

      {
        awardloader ?
          <AwardLoader />
          :
          awarddata.length ?
            <section className="Sec4" data-text="Awards">
              <div className="container">
                <div className="row align-items-center justify-content-center">
                  <div className="col-12 col-md-9 col-lg-8 text-center wow fadeInUp">
                    <span>{awardheading.title}</span>
                    <h2 className="Heading h1 fs-xl mb-4">{awardheading.heading}</h2>
                    <div dangerouslySetInnerHTML={{ __html: awardheading.description }} />
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-12">
                    <OwlCarousel className='owl-theme' loop margin={10} responsive={{ 0: { items: 1 }, 350: { items: 1 }, 460: { items: 1 }, 600: { items: 2 }, 768: { items: 2 }, 992: { items: 3 }, 1200: { items: 3 }, 1600: { items: 3 } }}>
                      {
                        awarddata.map((award, a) =>
                          <div key={a}>
                            {
                              helper.checkimagetype(award.image) ?
                                <a href={process.env.REACT_APP_API_IMAGEURL + 'clients/' + award.image} className="Aw-box" data-fancybox="awards">
                                  <picture>
                                    <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'clients/' + award.image} alt={award.title ?? ''} width="1600" height="650" />
                                  </picture>
                                </a>
                                :
                                <a href={process.env.REACT_APP_API_IMAGEURL + 'clients/webp/' + award.image + '.webp'} className="Aw-box" data-fancybox="awards">
                                  <picture>
                                    <source srcSet={process.env.REACT_APP_API_IMAGEURL + 'clients/webp/' + award.image + '.webp'} type="image/webp" />
                                    <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'clients/jpg/' + award.image + '.jpg'} alt={award.title ?? ''} width="1600" height="650" />
                                  </picture>
                                </a>
                            }
                          </div>
                        )
                      }
                    </OwlCarousel>
                  </div>
                </div>
              </div>
              <button type="button" className="h1 ArrowDown BottomSec" aria-label="Sections"><span></span></button>
            </section>
            :
            ''
      }

      {
        photosloader ?
          <PhotosLoader />
          :
          videos.length > 0 && photos.length > 0 ?

            <section className="Sec5" data-text="Gallery">
              <div className="container">
                <div className="row align-items-center justify-content-center">
                  <div className="col-12 col-md-9 col-lg-8 text-center wow fadeInUp">
                    <span>{eventheading.title}</span>
                    <h2 className="Heading h1 fs-xl mb-4">{eventheading.heading}</h2>
                    <div dangerouslySetInnerHTML={{ __html: eventheading.description }} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <ul className="nav nav-pills mb-4 gtab" id="pills-tab" role="tablist">
                      {
                        photos.length ?
                          <li className="nav-item" role="presentation"><button className="nav-link active" id="pills-photo-tab" data-bs-toggle="pill" data-bs-target="#pills-photo" type="button" role="tab" aria-controls="pills-photo" aria-selected="true">Photos</button></li>
                          : ""
                      }
                      {
                        videos.length ?
                          <li className="nav-item" role="presentation"><button className="nav-link" id="pills-video-tab" data-bs-toggle="pill" data-bs-target="#pills-video" type="button" role="tab" aria-controls="pills-video" aria-selected="false">Videos</button></li>
                          : ''}
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                      {
                        photos.length ?
                          <div className="tab-pane p-0 fade show active" id="pills-photo" role="tabpanel" aria-labelledby="pills-photo-tab" tabIndex="0">
                            <div className="row justify-content-center">
                              {
                                photos.map((gallery, ph) =>
                                  <div className="col-6 col-md-4 col-lg-3" key={ph}>
                                    {
                                      helper.checkimagetype(gallery.image) ?
                                        <a href={process.env.REACT_APP_API_IMAGEURL + 'album/gallery/' + gallery.image} className="ImgBox" data-fancybox="gallery">
                                          <picture>
                                            <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'album/gallery/' + gallery.image} alt={gallery.title} width="1600" height="650" />
                                          </picture>
                                        </a>
                                        :
                                        <a href={process.env.REACT_APP_API_IMAGEURL + 'album/gallery/webp/' + gallery.image + '.webp'} className="ImgBox" data-fancybox="gallery">
                                          <picture>
                                            <source srcSet={process.env.REACT_APP_API_IMAGEURL + 'album/gallery/webp/' + gallery.image + '.webp'} type="image/webp" />
                                            <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'album/gallery/jpg/' + gallery.image + '.jpg'} alt={gallery.title} width="1600" height="650" />
                                          </picture>
                                        </a>
                                    }
                                  </div>
                                )
                              }
                            </div>
                          </div>
                          : ''
                      }

                      {
                        videos.length ?
                          <div className="tab-pane VideoSec p-0 fade" id="pills-video" role="tabpanel" aria-labelledby="pills-video-tab" tabIndex="0">
                            <div className="row justify-content-center">
                              {
                                videos.map((video, v) =>
                                  <div key={v} className="col-6 col-md-4 col-lg-3">
                                    <div className="ImgBox">
                                      <div className="youtube-player" data-id={helper.youtubeid(video.youtube_url)}></div>
                                      <a href={video.youtube_url} data-fancybox="video" className="playVideo"> </a>
                                    </div>
                                  </div>
                                )
                              }
                            </div>
                          </div>
                          : ''
                      }
                    </div>
                  </div>
                </div>
              </div>
              <button type="button" className="h1 ArrowDown BottomSec" aria-label="Sections"><span></span></button>
            </section>
            : ''
      }

      {
        blogloader ?
          <BlogLoader />
          :
          blogs.length ?
            <section className="Sec6" data-text="Who We Are">
              <div className="container">
                <div className="row align-items-center justify-content-center">
                  <div className="col-12 col-md-9 col-lg-8 text-center wow fadeInUp">
                    <span>{blogheading.title}</span>
                    <h2 className="Heading h1 fs-xl mb-4">{blogheading.heading}</h2>
                    <div dangerouslySetInnerHTML={{ __html: blogheading.description }} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <OwlCarousel className='owl-theme blog' loop margin={10} responsive={{ 0: { items: 1 }, 350: { items: 1 }, 460: { items: 1 }, 600: { items: 2 }, 768: { items: 2 }, 992: { items: 3 }, 1200: { items: 3 }, 1600: { items: 3 } }}>
                      {
                        blogs.map((blog, b) =>
                          <Link to={'/blog/' + blog.alias} key={b} className="card">
                            <div className="card-header">
                              {
                                helper.checkimagetype(blog.image) ?
                                  <picture>
                                    <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'blog/' + blog.image} alt={blog.title} width="121" height="131" />
                                  </picture>
                                  :
                                  <picture>
                                    <source srcSet={process.env.REACT_APP_API_IMAGEURL + 'blog/webp/' + blog.image + '.webp'} type="image/webp" />
                                    <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'blog/jpg/' + blog.image + '.jpg'} alt={blog.title} width="121" height="131" />
                                  </picture>

                              }

                            </div>
                            <div className="card-body mt-1 text-center">
                              <span className="badge bgthm">{moment(blog.post_date).format('DD MMMM YYYY')}</span>
                              <h2 className="h5 mt-2">{blog.title}</h2>
                            </div>
                          </Link>
                        )
                      }
                    </OwlCarousel>
                  </div>
                </div>
              </div>
              <button type="button" className="h1 ArrowDown BottomSec" aria-label="Sections"><span></span></button>
            </section>
            :
            ''
      }

      {
        testimonials.length ?
          <section className="Sec7" data-text="Our Testimonial">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8 col-md-9 wow fadeInDown text-center">
                  <span>{testimonialheading.title}</span>
                  <h2 className="Heading h1 fs-xl mb-4">{testimonialheading.heading}</h2>
                  <div dangerouslySetInnerHTML={{ __html: testimonialheading.description }} />
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-11">
                  <OwlCarousel className="owl-theme Testi" loop items={1} margin={10}>
                    {
                      testimonials.map((testimonial, te) =>
                        <div className="card" key={te}>
                          <div className="card-header">
                            <span className="img">
                              {
                                helper.checkimagetype(testimonial.image) ?
                                  <img src={process.env.REACT_APP_API_IMAGEURL + 'testimonial/' + testimonial.image} alt="User Name" width="50" height="50" />
                                  :
                                  <img src={process.env.REACT_APP_API_IMAGEURL + 'testimonial/webp/' + testimonial.image + '.webp'} alt="User Name" width="50" height="50" />

                              }
                            </span>
                          </div>
                          <div className="card-body">
                            <h3 className="h4 fw-bold">{testimonial.title}</h3>
                            <div className="text" dangerouslySetInnerHTML={{ __html: testimonial.description }} />
                          </div>
                        </div>
                      )
                    }
                  </OwlCarousel>
                </div>
              </div>
            </div>
            <button type="button" className="h1 ArrowDown BottomSec" aria-label="Sections"><span></span></button>
          </section>
          :
          ''
      }


    </main>
  )
}

import React, { useEffect, useState } from 'react'
import { Link, NavLink, Outlet } from 'react-router-dom'
import '../asset/css/style.css'
import axios from 'axios';
import helper from '../Helper';
import moment from 'moment';
export default function Layout() {

    const [services, setServices] = useState([]);
    const [socialmedias, setSocialmedias] = useState([]);
    const [NavbarOpen, setNavbarOpen] = useState(false);

    const fetchData = async () => {
        const ServiceAPI = process.env.REACT_APP_API + 'services';
        const SocialMediaAPI = process.env.REACT_APP_API + 'socialmedia';

        const ServiceApiResponse = await axios.get(ServiceAPI);
        const SocialMediaAPIResponse = await axios.get(SocialMediaAPI);

        await axios.all([ServiceApiResponse, SocialMediaAPIResponse])
            .then((res) => {
                if (res[0]) { setServices(res[0].data.result.data); }
                if (res[1]) { setSocialmedias(res[1].data.result); }
            });
    }

    useEffect(() => {
        fetchData();
    }, [])


    return (
        <>
            <header></header>
            <nav className="navbar menu">
                <div className="st">
                    <div className="container">
                        <div className="row">
                            <div className="col logom">
                                <button className={NavbarOpen ? ' navbar-toggler MenuIcon d-lg-none' : 'navbar-toggler MenuIcon offcanvasClose d-lg-none'} onClick={() => setNavbarOpen((NavbarOpen) => !NavbarOpen)}><svg viewBox="0 0 30 26" fill="var(--white)"><path d="M9.42,19.56l-6.5-6.51L9.56,6.41C10.62,5.35,9.26,3.83,8.07,5l-8,8,8,8C9.08,22,10.52,20.66,9.42,19.56Z" /><path className="bar1" d="M1,0H29a1,1,0,0,1,0,2H1A1,1,0,0,1,1,0Z" /><path className="bar2" d="M1,24H29a1,1,0,0,1,0,2H1a1,1,0,1,1,0-2Z" /><path d="M10.93,12H29a1,1,0,0,1,0,2H10.93a1,1,0,0,1,0-2Z" /></svg></button>
                                <NavLink to="/" className="logo" title="Purple Unicorn"><img src={require('../asset/img/logo.svg').default} width="250" height="57" className="w-100" alt="Purple Unicorn" /></NavLink>
                            </div>
                            <div className="col MenuBar">
                                <div className={NavbarOpen ? ' offcanvas offcanvas-start justify-content-between show' : 'offcanvas offcanvas-start justify-content-between'} id="navigatin" data-bs-scroll="false" data-bs-backdrop="false" tabIndex="-1">
                                    <ul className="navbar-nav">
                                        <li className="nav-item"><NavLink className="nav-link" title="Home" to="/" onClick={() => setNavbarOpen(false)}><span>Home</span></NavLink></li>
                                        <li className="nav-item"><NavLink className="nav-link" title="About Us" to="about" onClick={() => setNavbarOpen(false)}><span>About</span></NavLink></li>
                                        <li className="nav-item"><NavLink className="nav-link" title="Our Founder" to="our-founder" onClick={() => setNavbarOpen(false)}><span>Founder</span></NavLink></li>

                                        {
                                            services.length ?
                                                <li className="nav-item dropdown"><NavLink title="Services" className="nav-link dropdown-toggle d-none d-lg-block" to="/services" onClick={() => setNavbarOpen(false)} role="button" aria-haspopup="true" aria-expanded="false"><span>Services</span></NavLink><a href='/#' title="Services" className="nav-link dropdown-toggle d-lg-none" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span>Services</span></a>
                                                    <ul className="dropdown-menu" aria-labelledby="servicesDropdown">
                                                        {
                                                            services.map((service, sr) =>
                                                                <li key={sr}>
                                                                    <Link title={service.title} to={"/service/" + service.alias} onClick={() => setNavbarOpen(false)}>
                                                                        {
                                                                            helper.checkimagetype(service.icon) ?
                                                                                <img src={process.env.REACT_APP_API_IMAGEURL + 'service/icon/' + service.icon} alt={service.title} />
                                                                                :
                                                                                <img src={process.env.REACT_APP_API_IMAGEURL + 'service/icon/webp/' + service.icon + '.webp'} alt={service.title} />
                                                                        }

                                                                        {service.title}
                                                                    </Link>
                                                                </li>
                                                            )
                                                        }
                                                    </ul>
                                                </li>
                                                : ''
                                        }

                                        <li className="nav-item dropdown d-lg-none"><a title="Gallery" className="nav-link dropdown-toggle" href='/#' role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span>Gallery</span></a>
                                            <ul className="dropdown-menu" aria-labelledby="galleryDropdown">
                                                <li><NavLink title="Photos" to="photos" onClick={() => setNavbarOpen(false)}>Photos</NavLink></li>
                                                <li><NavLink title="Videos" to="videos" onClick={() => setNavbarOpen(false)}>Videos</NavLink></li>
                                            </ul>
                                        </li>
                                        <li className="nav-item d-lg-none"><NavLink className="nav-link" title="Testimonial" to="testimonials" onClick={() => setNavbarOpen(false)}><span>Testimonial</span></NavLink></li>
                                        <li className="nav-item d-lg-none"><NavLink className="nav-link" title="Contact Us" to="contact-us" onClick={() => setNavbarOpen(false)}><span>Contact Us</span></NavLink></li>
                                    </ul>
                                    <div className="text-center p-3 mt-2 d-lg-none position-sticky sticky-bottom">
                                        <NavLink to="contact-us" onClick={() => setNavbarOpen(false)} className="ReqBtn text-white mb-4">Request a Quote</NavLink><br />
                                        <a href="tel:+919898989898" className="IconImg h5 text-white"><img src={require('../asset/img/support-w.svg').default} alt='support' width="32" height="32" className="me-2" /> (+91) 989 898 9898</a>
                                    </div>
                                </div>
                                <div className="LastH d-lg-none">
                                    <a href="tel:+919898989898" className="IconImg" title="Call Now"><img src={require('../asset/img/support1.svg').default} width="46" height="46" alt="support" /></a>
                                    <a href="mailto:contact@purpleunicorn.co.in" className="IconImg" title="Email"><img src={require('../asset/img/mail.svg').default} width="46" height="46" alt="email" /></a>
                                </div>
                            </div>
                            <div className="col LastH d-none d-lg-block">
                                <ul className="navbar-nav">
                                    <li className="nav-item dropdown"><a title="Gallery" href='/#' className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span>Gallery</span></a>
                                        <ul className="dropdown-menu" aria-labelledby="galleryDropdown">
                                            <li><NavLink title="Photos" to="photos" onClick={() => setNavbarOpen(false)}>Photos</NavLink></li>
                                            <li><NavLink title="Videos" to="videos" onClick={() => setNavbarOpen(false)}>Videos</NavLink></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item"><NavLink className="nav-link" title="Testimonial" to="testimonials" onClick={() => setNavbarOpen(false)}><span>Testimonial</span></NavLink></li>
                                    <li className="nav-item"><NavLink className="nav-link" title="Contact Us" to="contact-us" onClick={() => setNavbarOpen(false)}><span>Contact Us</span></NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>

            <Outlet />

            <footer className="ts">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 text-lg-start text-center">
                            <div className="d-inline-block text-center mb-4">
                                <NavLink to="/"><img src={require('../asset/img/logo-w.svg').default} width="250" height="57" className="flogo" alt="Purple Unicorn" /></NavLink>
                                <ul className="icons p-0 mt-4">
                                    {
                                        socialmedias.map((socialmedia, sm) => {

                                            if (socialmedia.title === 'facebook') {
                                                return (
                                                    <li key={sm}>
                                                        <a href={socialmedia.link} target="_blank" rel="noreferrer" title="Facebook">
                                                            <img loading="lazy" src={require("../asset/img/facebook-w.svg").default} alt="Facebook" width="24" height="24" />
                                                        </a>
                                                    </li>
                                                )
                                            }

                                            if (socialmedia.title === 'twitter') {
                                                return (
                                                    <li key={sm}>
                                                        <a href={socialmedia.link} target="_blank" rel="noreferrer" title="Twitter">
                                                            <img loading="lazy" src={require("../asset/img/twitter-w.svg").default} alt="Twitter" width="24" height="24" />
                                                        </a>
                                                    </li>
                                                )
                                            }

                                            if (socialmedia.title === 'linkedin') {
                                                return (
                                                    <li key={sm}>
                                                        <a href={socialmedia.link} target="_blank" rel="noreferrer" title="Linkedin">
                                                            <img loading="lazy" src={require("../asset/img/linkedin-w.svg").default} alt="Linkedin" width="24" height="24" />
                                                        </a>
                                                    </li>
                                                )
                                            }

                                            if (socialmedia.title === 'instagram') {
                                                return (
                                                    <li key={sm}>
                                                        <a href={socialmedia.link} target="_blank" rel="noreferrer" title="Instagram">
                                                            <img loading="lazy" src={require("../asset/img/instagram-w.svg").default} alt="Instagram" width="24" height="24" />
                                                        </a>
                                                    </li>
                                                )
                                            }

                                            if (socialmedia.title === 'youtube') {
                                                return (
                                                    <li key={sm}>
                                                        <a href={socialmedia.link} target="_blank" rel="noreferrer" title="Youtube">
                                                            <img loading="lazy" src={require("../asset/img/youtube-w.svg").default} alt="Youtube" width="24" height="24" />
                                                        </a>
                                                    </li>
                                                )
                                            }

                                            if (socialmedia.title === 'pinterest') {
                                                return (
                                                    <li key={sm}>
                                                        <a href={socialmedia.link} target="_blank" rel="noreferrer" title="Pinterest">
                                                            <img loading="lazy" src={require("../asset/img/pinterest-w.svg").default} alt="Pinterest" width="24" height="24" />
                                                        </a>
                                                    </li>
                                                )
                                            }

                                        })
                                    }

                                    <li className="d-none d-lg-inline-block"><a href="https://api.whatsapp.com/send?phone=+919353075858&amp;text=Hi, I would like to get more information.." target="_blank" rel="noreferrer" title="Whatsapp"><img loading="lazy" src={require('../asset/img/whatsapp-w.svg').default} alt="Whatsapp" width="24" height="24" /></a></li>
                                    <li className="d-lg-none"><a href="whatsapp://send?text=Hi, I would like to get more information..!&amp;phone=+919353075858" target="_blank" rel="noreferrer" title="Whatsapp"><img loading="lazy" src={require('../asset/img/whatsapp-w.svg').default} alt="Whatsapp" width="24" height="24" /></a></li>
                                </ul>
                                <p><small>&#169; {moment().format('YYYY')} <strong className="text-white">Purple Unicorn</strong>. All Rights Reserved.</small></p>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <h3 className="text-u h6 text-white">Quick Link</h3>
                            <ul className="links">
                                <li><NavLink to="/">Home</NavLink></li>
                                <li><NavLink to="about">About Us</NavLink></li>
                                <li><NavLink to="our-founder">Our Founder</NavLink></li>
                                <li><NavLink to="photos">Photo Gallery </NavLink></li>
                                <li><NavLink to="videos">Videos Gallery</NavLink></li>
                                <li><NavLink to="testimonials">Testimonial</NavLink></li>
                                <li><NavLink to="contact-us">Contact Us</NavLink></li>
                            </ul>
                        </div>
                        <div className="col-lg-4">
                            <div className="row">
                                <h3 className="text-u h6 text-white">Services</h3>
                                <div className="col-md-12">
                                    <ul className="links">
                                        {
                                            services.map((service, sr) =>
                                                <li key={sr}>
                                                    <Link title={service.title} to={"/service/" + service.alias}>
                                                        {service.title}
                                                    </Link>
                                                </li>

                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <h3 className="text-u h6 text-white">Our Locations</h3>
                            <ul className="ConInfo">
                                <li><img src={require('../asset/img/location-w.svg').default} height="50" width="50" alt="location" /> <span>3B, 3rd floor, Chandra enclave, Bariatu housing colony, Ranchi, Jharkhand</span></li>
                                <li><img src={require('../asset/img/support-w.svg').default} height="50" width="50" alt="contact" /> <a href="tel:(+91) 8084840910" className="">(+91) 80848 40910</a></li>
                                <li><img src={require('../asset/img/mail-w.svg').default} height="50" width="50" alt="email" /> <a href="mailto:sumit.purpleunicorn@gmail.com">sumit.purpleunicorn@gmail.com</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="fbottom mt-4">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6 text-start">
                                <p><small>
                                    <NavLink to="terms-and-conditions" className="me-4 text-white">Terms & Conditions</NavLink>
                                    <NavLink to="privacy-policy" className="me-4 text-white">Privacy Policy</NavLink>
                                    <NavLink to="faqs" className="me-4 text-white">FAQs</NavLink>
                                    <NavLink to="blog" className="me-4 text-white">Blogs</NavLink>
                                </small>
                                </p>
                            </div>
                            <div className="col-12 col-md-6 text-end">
                                <p><small>Made with &nbsp;<strong><span className="text-danger">&#10084;</span></strong>&nbsp; by <strong><a href="https://www.samwebstudio.com/" className="text-white" rel="noreferrer" target="_blank">SAM Web Studio</a></strong></small></p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    )
}

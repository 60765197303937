import React, { useEffect, useState } from 'react'
import '../asset/cuscomcss/about.css'
import axios from 'axios';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import ContentLoader from 'react-content-loader'
import { NavLink } from 'react-router-dom'
import helper from '../Helper';

import CmsLoader from '../loader/Cms';
import AwardLoader from '../loader/Service';
import TeamLoader from '../loader/Team';
import Meta from './Meta';

export default function About() {
    Fancybox.bind('[data-fancybox="awards"]', {});
    const [aboutdata, setAboutData] = useState('');
    const [teamdata, setTeamData] = useState([]);
    const [teaminfo, setTeamInfo] = useState('');
    const [awarddata, setAwardData] = useState([]);
    const [awardcmsdata, setAwardCmsData] = useState([]);
    const [pageloader, setPageLoader] = useState(true);
    const [modalloader, setModalLoader] = useState(false);

    const [cmsloader, setCmsloader] = useState(true);
    const [awardloader, setAwardloader] = useState(true);
    const [teamloader, setTeamloader] = useState(true);

    const fetchData = async () => {

        const AboutApI = process.env.REACT_APP_API + 'getcmsdata/6';
        const AwardCmsApI = process.env.REACT_APP_API + 'getcmsdata/3';
        const TeamApI = process.env.REACT_APP_API + 'teams';
        const AwardApI = process.env.REACT_APP_API + 'awards';

        const AboutApiResponse = await axios.get(AboutApI);
        const TeamApiResponse = await axios.get(TeamApI);
        const AwardApiResponse = await axios.get(AwardApI);
        const AwardCmsApIResponse = await axios.get(AwardCmsApI);

        await axios.all([AboutApiResponse, TeamApiResponse, AwardApiResponse,AwardCmsApIResponse])
            .then((response) => {
                if (response[0]) { setAboutData(response[0].data.result); setCmsloader(false); }
                if (response[1]) { setTeamData(response[1].data.result.data); setTeamloader(false); }
                if (response[2]) { setAwardData(response[2].data.result.data); setAwardloader(false); }
                if (response[3]) { setAwardCmsData(response[3].data.result); }
                setPageLoader(false);
            });
    }

    useEffect(() => {
        fetchData();
    }, []);

    async function getTeamInfo(teammember) {
        setModalLoader(true);
        await axios.get(process.env.REACT_APP_API + 'teams/' + teammember)
            .then((res) => {
                setTeamInfo(res.data.result.data[0]);
                setModalLoader(false);
            });
    }

    return (
        <>
            <Meta id={2}/>
            <main>
                <section className="inner-banner Ibanner">
                    <div className="bgimg">
                        <picture>
                            <source srcSet={require('../asset/img/banner-bg1.webp')} type="image/webp" />
                            <img loading="lazy" src={require('../asset/img/banner-bg1.jpg')} alt="slide1" height="452" width="452" />
                        </picture>
                    </div>
                    <div className="section">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    <ol className="breadcrumb justify-content-center">
                                        <li className="breadcrumb-item"><NavLink to="/"><img src={require('../asset/img/home-w.svg').default} width="14" height="14" alt="Home" /></NavLink></li>
                                        <li className="breadcrumb-item"><a href='/#' style={{cursor: 'pointer', pointerEvents: 'none'}} aria-current="page">About Us</a></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                cmsloader ?
                    <CmsLoader />
                    :
                    <section data-text={pageloader ? '' : "Welcome to"}>
                        {
                            pageloader ?
                                <div className='text-center'>
                                    <ContentLoader
                                        viewBox="0 0 400 160"
                                        height={160}
                                        width={400}
                                        backgroundColor="transparent"
                                    >
                                        <circle cx="150" cy="86" r="8" />
                                        <circle cx="194" cy="86" r="8" />
                                        <circle cx="238" cy="86" r="8" />
                                    </ContentLoader>
                                </div>
                                :
                                <div className="container">
                                    <div className="row align-items-center justify-content-center">
                                        <div className="col-12 col-md-9 col-lg-8 text-center wow fadeInUp">
                                            <h1 className="Heading fs-xl mb-4">{aboutdata.title ?? ''}</h1>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 CmsPage">
                                            {
                                                helper.checkimagetype(aboutdata.image) ?
                                                    <picture className="mainImg float-md-end mb-md-5 ms-md-5 col-md-3">
                                                        <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'cms/' + aboutdata.image} alt={aboutdata.title ?? ''} className="w-100" width="900" height="453" />
                                                    </picture>
                                                    :
                                                    <picture className="mainImg float-md-end mb-md-5 ms-md-5 col-md-3">
                                                        <source srcSet={process.env.REACT_APP_API_IMAGEURL + 'cms/webp/' + aboutdata.image+'.webp'} type="image/webp" />
                                                        <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'cms/jpg/' + aboutdata.image+'.jpg'} alt={aboutdata.title ?? ''} className="w-100" width="900" height="453" />
                                                    </picture>
                                            }
                                            <h2>{aboutdata.heading ?? ''}</h2>    
                                            <div dangerouslySetInnerHTML={{ __html: aboutdata.description }} />
                                        </div>
                                    </div>
                                </div>
                        }
                    </section>
                }
                {
                awardloader ?
                    <AwardLoader />
                    :
                    awarddata.length ?
                        <section className="Sec4" data-text="Awards" data-color="light">
                            <div className="container">
                                <div className="row align-items-center justify-content-center">
                                    <div className="col-12 col-md-9 col-lg-8 text-center wow fadeInUp">
                                        <span>{awardcmsdata.title ?? 'Purple Unicorn'}</span>
                                        <h2 className="Heading h1 fs-xl mb-4">{awardcmsdata.heading ?? 'Our Awards'}</h2>
                                        <div dangerouslySetInnerHTML={{__html:awardcmsdata.description ?? ''}}/>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-12">
                                        <OwlCarousel className="owl-carousel" loop margin={10}>
                                            {
                                                awarddata.map((award, a) =>
                                                    <div key={a}>
                                                        {
                                                            helper.checkimagetype(award.image) ?
                                                                <a href={process.env.REACT_APP_API_IMAGEURL + 'clients/' + award.image} className="Aw-box" data-fancybox="awards">
                                                                    <picture>
                                                                        <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'clients/' + award.image} alt={award.title ?? ''} width="1600" height="650" />
                                                                    </picture>
                                                                </a>
                                                                :
                                                                <a href={process.env.REACT_APP_API_IMAGEURL + 'clients/webp/' + award.image+'.webp'} className="Aw-box" data-fancybox="awards">
                                                                    <picture>
                                                                        <source srcSet={process.env.REACT_APP_API_IMAGEURL + 'clients/webp/' + award.image+'.webp'} type="image/webp" />
                                                                        <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'clients/jpg/' + award.image+'.jpg'} alt={award.title ?? ''} width="1600" height="650" />
                                                                    </picture>
                                                                </a>
                                                        }
                                                    </div>
                                                )
                                            }
                                        </OwlCarousel>
                                    </div>
                                </div>
                            </div>
                        </section>
                        :
                        ''
                }

                {
                teamloader ?
                    <TeamLoader />
                    :
                    teamdata.length ?
                        <section className="team">
                            <div className="container">
                                <div className="row align-items-center justify-content-center">
                                    <div className="col-12 col-md-9 col-lg-8 text-center wow fadeInUp">
                                        <span>Purple Unicorn</span>
                                        <h2 className="Heading h1 fs-xl mb-4">Management Team</h2>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    {
                                        teamdata.map((team, i) =>
                                            <div key={i} className="col-lg-3 col-md-4 col-sm-6">
                                                <div className="card FeaturesBox">
                                                    <div className="card-body" onClick={() => { getTeamInfo(team.id) }}>
                                                        <div className="TeamMeb">
                                                            {
                                                                helper.checkimagetype(team.image) ?
                                                                    <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'team/' + team.image} alt={team.title} height="162" width="162" />

                                                                    :
                                                                    <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'team/webp/' + team.image+'.webp'} alt={team.title} height="162" width="162" />

                                                            }
                                                            <a href="/#" data-bs-target="#Profile" data-bs-toggle="modal">View Profile <i className="fal fa-plus-hexagon ms-2"></i></a>
                                                        </div>
                                                        <h3 className="h5 m-0 thm fw-bold" data-bs-target="#Profile" data-bs-toggle="modal">{team.title}</h3>
                                                        <p className="text-secondary"><small>{team.designation}</small></p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                </div>
                            </div>
                        </section>

                        :
                        ''
                }

            </main>
            <div className="modal fade TeamsPopup" id="Profile" aria-hidden="true" aria-labelledby="ProfileLabel" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">

                    {
                        modalloader ?
                            <div className="modal-content">
                                <div className='text-center'>
                                    <ContentLoader
                                        viewBox="0 0 400 160"
                                        height={160}
                                        width={400}
                                        backgroundColor="transparent"
                                    >
                                        <circle cx="150" cy="86" r="8" />
                                        <circle cx="194" cy="86" r="8" />
                                        <circle cx="238" cy="86" r="8" />
                                    </ContentLoader>
                                </div>
                            </div>
                            :

                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="UserName">{teaminfo.title}<span>{teaminfo.designation}</span></h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    {
                                        helper.checkimagetype(teaminfo.image) ?
                                            <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'team/' + teaminfo.image} alt={teaminfo.title} height="275" width="162" className="col-12 col-md-4 float-start h-auto" />

                                            :
                                            <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'team/webp/' + teaminfo.image+'.webp'} alt={teaminfo.title} height="275" width="162" className="col-12 col-md-4 float-start h-auto" />

                                    }
                                    <div dangerouslySetInnerHTML={{ __html: teaminfo.description }} />
                                </div>
                                <div className="modal-footer justify-content-center">
                                    <ul className="icons iblack">
                                        {teaminfo.link_4 ?
                                            <li><a href={teaminfo.link_4} rel="noreferrer" target="_blank"><img src={require('../asset/img/facebook.svg').default} alt='facebook' /></a></li>
                                            : ''}
                                        {teaminfo.link_2 ?
                                            <li><a href={teaminfo.link_2} rel="noreferrer" target="_blank"><img src={require('../asset/img/twitter.svg').default} alt='twitter' /></a></li>
                                            : ''}
                                        {teaminfo.link_1 ?
                                            <li><a href={teaminfo.link_1} rel="noreferrer" target="_blank"><img src={require('../asset/img/linkedin.svg').default} alt='linkedin' /></a></li>
                                            : ''}
                                        {teaminfo.link_3 ?
                                            <li><a href={teaminfo.link_3} rel="noreferrer" target="_blank"><img src={require('../asset/img/instagram.svg').default} alt='instagram' /></a></li>
                                            : ''}
                                    </ul>
                                </div>
                            </div>
                    }

                </div>
            </div>
        </>
    )
}

const helper = {
    checkimagetype : (image)=>{
        if (image) {
            let imageArr = image.split('.');
            imageArr = imageArr.slice(-1);
            let stringimg = imageArr.toString(); 
            if (stringimg === "webp" || stringimg === "svg" || stringimg === "avif") {          
                return true;
            } else {
            return false;
            }
        } else {
            return false;
        }
    },
    youtubeid : (video)=>{
        if(video){
            let Arr = video.split('v=');
            let Arr2 = video.split('/');
            let ArrDara = Arr.slice(-1);
            let ArrDara2 = Arr2.slice(-1);
            if(ArrDara){
                return ArrDara;
            }else{
                return ArrDara2;
            }
            
        }
    },
    seprateHeading : (heading) => {
        if(heading){
            let Arr = heading.split(' ');
            let ArrDara = Arr.slice(-1);
            let StringData2 = ArrDara.toString();
            Arr.splice(-1);
            let StringData1 = Arr.toString().replaceAll(',', " ");
            console.warn(StringData1);
            return [StringData1,StringData2];
        }
    },
    seprateArray : (Arr,n) =>{
        if(Arr){
            let items = Arr.split(' ');
            const x = Math.ceil(items.length / n);
            const thirdPart = items.splice(-x);
            const secondPart = items.splice(-x);
            const firstPart = items; 
            
            return [
                firstPart.toString().replace(',',' '),
                secondPart.toString().replace(',',' '),
                thirdPart.toString().replace(',',' ')
            ];

        }        
    }
}
export default helper;
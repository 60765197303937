import React, { useEffect, useState } from 'react'
import { Link, NavLink, useParams } from 'react-router-dom'
import "../asset/cuscomcss/serviceinner.css"
import axios from 'axios';
import helper from '../Helper';
import Servicebox from './Servicebox';

import CmsLoader from '../loader/ServicesD';
import ServicesLoader from '../loader/Service';
export default function Servicedetail() {
    const params = useParams();
    const [serviceinfo, setServiceInfo] = useState('');
    const [services, setServices] = useState([]);
    const [pageloader, setPageLoader] = useState(true);
    const [servicesloader, setServicesLoader] = useState(true);

    const fetchData = async () => {
        setPageLoader(true);
        const InfoApi = process.env.REACT_APP_API + 'services/' + params.alias;
        const ServiceAPI = process.env.REACT_APP_API + 'services';
        const InfoApiResponse = await axios.get(InfoApi);
        const ServiceApiResponse = await axios.get(ServiceAPI);
        await axios.all([InfoApiResponse, ServiceApiResponse])
            .then((res) => {
                if (res[0]) { setServiceInfo(res[0].data.result); setPageLoader(false); }
                if (res[1]) { setServices(res[1].data.result.data); setServicesLoader(false);}
            });
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [params]);
    return (
        <>
            <main>
                <section className="inner-banner Ibanner">
                    <div className="bgimg">
                        <picture>
                            <source srcSet={require('../asset/img/banner-bg1.webp')} type="image/webp" />
                            <img loading="lazy" src={require('../asset/img/banner-bg1.jpg')} alt="slide1" height="452" width="452" />
                        </picture>
                    </div>
                    <div className="section">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    <ol className="breadcrumb justify-content-center">
                                        <li className="breadcrumb-item"><NavLink to="/"><img src={require('../asset/img/home-w.svg').default} width="14" height="14" alt="Home" /></NavLink></li>
                                        <li className="breadcrumb-item"><NavLink to="/services">Services</NavLink></li>
                                        <li className="breadcrumb-item"><a href='/#' style={{cursor: 'pointer', pointerEvents: 'none'}} aria-current="page">{serviceinfo.title}</a></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    pageloader ?
                        <CmsLoader/>
                        :
                        <>
                            <section className="Home" data-text="Services">
                                <div className="container">
                                    <div className="row align-items-center justify-content-center">
                                        <div className="col-12 col-md-9 col-lg-8 text-center wow fadeInUp">
                                            <h1 className="Heading fs-xl mb-4">{serviceinfo.title}</h1>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            {
                                                helper.checkimagetype(serviceinfo.image) ?
                                                    <picture className="mainImg float-md-end mb-md-5 ms-md-5 col-md-5">
                                                        <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'service/' + serviceinfo.image} alt={serviceinfo.title} className="w-100" width="900" height="453" />
                                                    </picture>
                                                    :
                                                    <picture className="mainImg float-md-end mb-md-5 ms-md-5 col-md-5">
                                                        <source srcSet={process.env.REACT_APP_API_IMAGEURL + 'service/webp/' + serviceinfo.image + '.webp'} type="image/webp" />
                                                        <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'service/jpg/' + serviceinfo.image + '.jpg'} alt={serviceinfo.title} className="w-100" width="900" height="453" />
                                                    </picture>
                                            }
                                            <div dangerouslySetInnerHTML={{ __html: serviceinfo.description }} />
                                        </div>
                                    </div>
                                </div>

                            </section>

                            {
                                serviceinfo.events ?
                                    <section className="pt-3 Home MoreSer">
                                        <div className="container">
                                            {
                                                serviceinfo.events.map((event, e) =>
                                                    <div className="row CmsPage MoreSerBoxs" key={e}>
                                                        <div className="col-md-6 img">
                                                            {
                                                                helper.checkimagetype(event.image) ?
                                                                    <picture>
                                                                        <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'service/facility/' + event.image} alt={event.title} className="w-100" width="900" height="453" />
                                                                    </picture>
                                                                    :
                                                                    <picture>
                                                                        <source srcSet={process.env.REACT_APP_API_IMAGEURL + 'service/facility/webp/' + event.image + '.webp'} type="image/webp" />
                                                                        <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'service/facility/jpg/' + event.image + '.jpg'} alt={event.title} className="w-100" width="900" height="453" />
                                                                    </picture>
                                                            }

                                                        </div>
                                                        <div className="col-md-6 text">
                                                            <div>
                                                                <h2>{event.title}</h2>
                                                                <div dangerouslySetInnerHTML={{ __html: event.description }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </section>
                                    : ''
                            }

                        </>
                }

                {
                    servicesloader ?
                        <ServicesLoader />
                        :
                        services.length ?
                            <section className="Sec2" data-text="All Services">
                                <div className="container">
                                    <div className="row align-items-center justify-content-center">
                                        <div className="col-12 col-md-9 col-lg-8 text-center wow fadeInUp">
                                            <h1 className="Heading fs-xl mb-4">Other Services</h1>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        {
                                            services.map((service, s) =>
                                                <Servicebox service={service} key={s} />
                                            )
                                        }
                                    </div>
                                </div>
                            </section>
                            : ''
                }

            </main >
            {
                serviceinfo ?
                    <Link to={'/contact-us?service=' + serviceinfo.alias + '&serviceid=' + serviceinfo.id} className='GetAq'>Request a Quote</Link>
                :
                    ''
            }
        </>
    )
}

import React from 'react'
import { Link } from 'react-router-dom'
import helper from '../Helper'

export default function Servicebox(props) {
    return (
        <>
            <div className="col-md-4 col-6 wow fadeInUp">
                <Link to={"/service/" + props.service.alias} className="SerBlock">
                    {
                        helper.checkimagetype(props.service.image) ?
                            <picture>
                                <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'service/' + props.service.image} alt={props.service.title} width="1600" height="650" />
                            </picture>
                            :
                            <picture>
                                <source srcSet={process.env.REACT_APP_API_IMAGEURL + 'service/' + props.service.image + '.webp'} type="image/webp" />
                                <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'service/jpg/' + props.service.image + '.jpg'} alt={props.service.title} width="1600" height="650" />
                            </picture>
                    }

                    <div>
                        {
                            helper.checkimagetype(props.service.icon) ?
                                <img src={process.env.REACT_APP_API_IMAGEURL + 'service/icon/' + props.service.icon} alt={props.service.title} width="59" height="55" />
                                :
                                <img src={process.env.REACT_APP_API_IMAGEURL + 'service/icon/webp/' + props.service.icon + '.webp'} alt={props.service.title} width="59" height="55" />
                        }

                        <h3>{props.service.title}</h3>
                        <small className="LinkArrow"><span>Read More</span><i></i></small>
                    </div>
                </Link>
            </div>
        </>
    )
}

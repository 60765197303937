import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { NavLink } from 'react-router-dom'
import helper from '../Helper';

import CmsLoader from '../loader/Cms';
import Meta from './Meta';

export default function Founder() {
    const [data, setData] = useState('');
    const [pageloader, setPageLoader] = useState(true);

    const fetchData = async () => {

        const AboutApI = process.env.REACT_APP_API + 'getcmsdata/10';

        const AboutApiResponse = await axios.get(AboutApI);

        await axios.all([AboutApiResponse])
            .then((response) => {
                if (response[0]) { setData(response[0].data.result); }
                setPageLoader(false);
            });
    }


    useEffect(() => {
        fetchData();
    }, []);
    return (
        <>
            <Meta id={12}/>
            <main>
                <section className="inner-banner Ibanner">
                    <div className="bgimg">
                        <picture>
                            <source srcSet={require('../asset/img/banner-bg1.webp')} type="image/webp" />
                            <img loading="lazy" src={require('../asset/img/banner-bg1.jpg')} alt="slide1" height="452" width="452" />
                        </picture>
                    </div>
                    <div className="section">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    <ol className="breadcrumb justify-content-center">
                                        <li className="breadcrumb-item"><NavLink to="/"><img src={require('../asset/img/home-w.svg').default} width="14" height="14" alt="Home" /></NavLink></li>
                                        <li className="breadcrumb-item"><a href='/#' style={{cursor: 'pointer', pointerEvents: 'none'}} aria-current="page">Our Founder</a></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    pageloader ?
                    <CmsLoader />
                    :
                        <section data-text={pageloader ? '' : "Purple Unicorn"}>
                            <div className="container">
                                <div className="row align-items-center justify-content-center">
                                    <div className="col-12 col-md-9 col-lg-8 text-center wow fadeInUp">
                                        <h1 className="Heading fs-xl mb-4">{data.title ?? ''}</h1>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 CmsPage">
                                        {
                                            helper.checkimagetype(data.image) ?
                                                <picture className="mainImg float-md-end mb-md-5 ms-md-5 col-md-3">
                                                    <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'cms/' + data.image} alt={data.title ?? ''} className="w-100" width="900" height="453" />
                                                </picture>
                                                :
                                                <picture className="mainImg float-md-end mb-md-5 ms-md-5 col-md-3">
                                                    <source srcSet={process.env.REACT_APP_API_IMAGEURL + 'cms/webp/' + data.image+'.webp'} type="image/webp" />
                                                    <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'cms/jpg/' + data.image+'.jpg'} alt={data.title ?? ''} className="w-100" width="900" height="453" />
                                                </picture>
                                        }
                                        <h2>{data.heading ?? ''}</h2>
                                        <div dangerouslySetInnerHTML={{ __html: data.description }} />
                                    </div>
                                </div>
                            </div>
                        </section>
                }
            </main>
        </>
    )
}

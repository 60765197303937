import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import './asset/css/main-style.css';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './components/Home';
import Layout from './components/Layout';
import About from './components/About';
import Page404 from './components/Page404';
import Founder from './components/Founder';
import Testimonial from './components/Testimonial';
import Contact from './components/Contact';
import Scrolltop from './ScrollTop';
import Faqs from './components/Faqs';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import Blog from './components/Blog';
import Blogdetail from './components/Blogdetail';
import Servicedetail from './components/Servicedetail';
import Services from './components/Services';
import Photos from './components/Photos';
import Videos from './components/Videos';
import Galleryinner from './components/Galleryinner';
function App() {
  return (

    <>
      <BrowserRouter basename='/'>
        <Scrolltop/>
        <Routes>
          <Route path='/' element={<Layout/>}>
            <Route index element={<Home />} />
            <Route path='about' element={<About />} />
            <Route path='our-founder' element={<Founder />} />
            <Route path='testimonials' element={<Testimonial />} />
            <Route path='contact-us' element={<Contact />} />
            <Route path='terms-and-conditions' element={<Terms />} />
            <Route path='privacy-policy' element={<Privacy />} />
            <Route path='videos' element={<Videos />} />
            <Route path='photos' element={<Photos />} />
            <Route path='gallery/:alias' element={<Galleryinner />} />
            <Route path='faqs' element={<Faqs />} />
            <Route path='blog' element={<Blog />} />
            <Route path='blog/:alias' element={<Blogdetail />} />
            <Route path='services' element={<Services />} />
            <Route path='service/:alias' element={<Servicedetail />} />
            {/* <Route path='blog/' element={<Blog/>}>
              <Route path=':id' element={<Blogdetail/>}/>
            </Route> */}
            <Route path='/*' element={<Page404 />} />
          </Route>
        </Routes>
      </BrowserRouter>


    </>

  );
}

export default App;

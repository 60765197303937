import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Servicebox from './Servicebox';
import { NavLink } from 'react-router-dom';

import ServicesLoader from '../loader/Service';
import Meta from './Meta';

export default function Services() {
    const [services, setServices] = useState([]);
    const [pageloader, setPageLoader] = useState(true);

    const fetchData = async () => {
        setPageLoader(true);
        const ServiceAPI = process.env.REACT_APP_API + 'services';
        const ServiceApiResponse = await axios.get(ServiceAPI);
        await axios.all([ServiceApiResponse])
            .then((res) => {
                if (res[0]) { setServices(res[0].data.result.data); }
                setPageLoader(false);
            });
    }

    useEffect(() => {
        fetchData();
    }, [])
    return (
        <>
        <Meta id={7}/>
            <main>
                <section class="inner-banner Ibanner">
                    <div class="bgimg">
                        <picture>
                            <source srcSet={require('../asset/img/banner-bg1.webp')} type="image/webp" />
                            <img loading="lazy" src={require('../asset/img/banner-bg1.jpg')} alt="slide1" height="452" width="452" />
                        </picture>
                    </div>
                    <div class="section">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-12">
                                    <ol class="breadcrumb justify-content-center">
                                        <li className="breadcrumb-item"><NavLink to="/"><img src={require('../asset/img/home-w.svg').default} width="14" height="14" alt="Home" /></NavLink></li>
                                        <li class="breadcrumb-item"><a href='/#' style={{cursor: 'pointer', pointerEvents: 'none'}} aria-current="page">Services</a></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    pageloader ?
                        <ServicesLoader />
                        :
                        <section class="Sec2" data-text={pageloader ? '' : "All Services"}>
                    

                            <div class="container">
                                <div class="row align-items-center justify-content-center">
                                    <div class="col-12 col-md-9 col-lg-8 text-center wow fadeInUp">
                                        <h1 class="Heading fs-xl mb-4">What we do?</h1>
                                    </div>
                                </div>
                                <div class="row justify-content-center">
                                    {
                                        services.map((service, s) =>
                                            <Servicebox service={service} key={s} />
                                        )
                                    }
                                </div>
                            </div>
                        </section>
                }
            </main>
        </>
    )
}

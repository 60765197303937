import React, { useEffect, useState } from 'react'
import { Link, NavLink, useParams } from 'react-router-dom'
import '../asset/cuscomcss/bloginner.css'
import '../asset/css/blog.css'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { ToastContainer, toast } from 'react-toastify';
import {
  EmailIcon,EmailShareButton,
  FacebookIcon,FacebookShareButton,
  LinkedinIcon,LinkedinShareButton,
  RedditIcon,RedditShareButton,
  TelegramIcon,TelegramShareButton,
  TwitterIcon,TwitterShareButton,
  WhatsappIcon,WhatsappShareButton,
} from "react-share";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
// import ContentLoader from 'react-content-loader';
import moment from 'moment';
import helper from '../Helper';
import CmsLoader from '../loader/Cms';
export default function Blogdetail() {
  const params = useParams();
  const [bloginfo, setBloginfo] = useState('');
  const [topblog, setTopblog] = useState([]);
  const [related, setRelated] = useState([]);
  const [categories, setCategory] = useState([]);
  const [pageloader, setPageLoader] = useState(false);

  const [btnloader, setBtnloader] = useState(false);
  const [email, setEmail] = useState("");
  const [emailerror, setEmailerror] = useState("");

  const fetchData = async () => {
    setPageLoader(true);
    const BlogInfoApi = process.env.REACT_APP_API + 'blogs/' + params.alias;
    const TopBlogApi = process.env.REACT_APP_API + 'blogs?type=topblogs&preblog=' + params.alias;
    const RelatedApi = process.env.REACT_APP_API + 'blogs?preblog=' + params.alias;
    const BlogCategoryApi = process.env.REACT_APP_API + 'blogcategory';

    const BlogInfoApiResponse = await axios.get(BlogInfoApi);
    const TopBlogApiResponse = await axios.get(TopBlogApi);
    const RelatedApiResponse = await axios.get(RelatedApi);
    const BlogCategoryApiResponse = await axios.get(BlogCategoryApi);

    await axios.all([BlogInfoApiResponse, TopBlogApiResponse, RelatedApiResponse, BlogCategoryApiResponse])
      .then((res) => {
        if (res[0]) {
          setBloginfo(res[0].data.result);
          setPageLoader(false);
        }
        if (res[1]) { setTopblog(res[1].data.result.data); }
        if (res[2]) { setRelated(res[2].data.result.data); }
        if (res[3]) { setCategory(res[3].data.result); }

      });
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [params]);

  async function SubscribeNewsletter(e) {
    e.preventDefault();
    setBtnloader(true);
    setEmailerror('');
    await axios.post(process.env.REACT_APP_API + 'newsletter', { email: email })
      .then((response) => {
        toast.success(response.data.message);
        setBtnloader(false);
        setEmail("");
      })
      .catch((errors) => {
        if (errors.response !== undefined) {
          let rterror = errors.response.data.errors;
          setEmailerror(rterror.email);
          setBtnloader(false);
        }
      });
  }

  return (
    <>
      <main>
        <section className="inner-banner Ibanner">
          <div className="bgimg">
            <picture>
              <source srcSet={require('../asset/img/banner-bg1.webp')} type="image/webp" />
              <img loading="lazy" src={require('../asset/img/banner-bg1.jpg')} alt="slide1" height="452" width="452" />
            </picture>
          </div>
          <div className="section">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12">
                  <ol className="breadcrumb justify-content-center">
                    <li className="breadcrumb-item"><NavLink to="/"><img src={require('../asset/img/home-w.svg').default} width="14" height="14" alt="Home" /></NavLink></li>
                    <li className="breadcrumb-item"><NavLink to="/blog">Blog</NavLink></li>
                    <li className="breadcrumb-item"><a href='/#' style={{cursor: 'pointer', pointerEvents: 'none'}} aria-current="page">Blog Details</a></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="BlogDetail BlogCategory">
          {
            pageloader ?
              <CmsLoader/>
              :

              <div className="container">
                <div className="row">
                  <div className="col-lg-9 col-md-8 BlogList">
                    <div className="DetailTop mb-4">
                      {
                        helper.checkimagetype(bloginfo.banner) ?
                          <picture>
                            <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'blog/banner/' + bloginfo.banner} alt={bloginfo.title} width="200" height="200" />
                          </picture>
                          :
                          <picture>
                            <source srcSet={process.env.REACT_APP_API_IMAGEURL + 'blog/banner/webp/' + bloginfo.banner + '.webp'} type="image/webp" />
                            <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'blog/banner/jpg/' + bloginfo.banner + '.jpg'} alt={bloginfo.title} width="200" height="200" />
                          </picture>
                      }

                      <h1 className="mt-4 h3">{bloginfo.title}</h1>
                      <div className="d-flex mt-2"><a href="/#"><i className="fal fa-user-circle thm"></i> Admin</a> | <span><i className="far fa-calendar-alt thm"></i> {moment(bloginfo.post_date).format('DD MMMM YYYY')}</span></div>
                    </div>
                    <div className="CmsPage mt-3" dangerouslySetInnerHTML={{ __html: bloginfo.description }} />
                    <div className="sharebox mt-4">
                      <div className="row justify-content-between">
                        <div className="col">
                          <strong>Share Now</strong>
                          <div id="social-links">
                            <ul>
                              <li><EmailShareButton url={process.env.REACT_APP_BASEURL + 'blog/' + params.alias}><EmailIcon size={32} round={true} /></EmailShareButton></li>
                              <li><FacebookShareButton url={process.env.REACT_APP_BASEURL + 'blog/' + params.alias}><FacebookIcon size={32} round={true} /> {shareCount => <span className="myShareCountWrapper">{shareCount}</span>}</FacebookShareButton></li>
                              <li><TwitterShareButton url={process.env.REACT_APP_BASEURL + 'blog/' + params.alias}><TwitterIcon size={32} round={true} /></TwitterShareButton></li>
                              <li><LinkedinShareButton url={process.env.REACT_APP_BASEURL + 'blog/' + params.alias}><LinkedinIcon size={32} round={true} /></LinkedinShareButton></li>
                              <li><TelegramShareButton url={process.env.REACT_APP_BASEURL + 'blog/' + params.alias}><TelegramIcon size={32} round={true} /></TelegramShareButton></li>
                              <li><WhatsappShareButton url={process.env.REACT_APP_BASEURL + 'blog/' + params.alias}><WhatsappIcon size={32} round={true} /></WhatsappShareButton></li>
                              <li><RedditShareButton url={process.env.REACT_APP_BASEURL + 'blog/' + params.alias}><RedditIcon size={32} round={true} /></RedditShareButton></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    {
                      related.length ?

                        <div className="Sec6 mt-4 listing">
                          <h3 className="Heading">Related Articles</h3>
                          <OwlCarousel margin={10} className="owl-carousel blog">
                            {
                              related.map((rel, r) =>
                                <Link to={"/blog/" + rel.alias} className="card" key={r}>
                                  <div className="card-header">
                                    {
                                      helper.checkimagetype(rel.image) ?
                                        <picture>
                                          <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'blog/' + rel.image} alt={rel.title} width="121" height="131" />
                                        </picture>
                                        :
                                        <picture>
                                          <source srcSet={process.env.REACT_APP_API_IMAGEURL + 'blog/webp/' + rel.image + '.webp'} type="image/webp" />
                                          <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'blog/jpg/' + rel.image + '.jpg'} alt={rel.title} width="121" height="131" />
                                        </picture>
                                    }
                                  </div>
                                  <div className="card-body mt-1 text-center">
                                    <span className="badge bgthm">{moment(rel.post_date).format('DD MMMM YYYY')}</span>
                                    <h3 className="h5 text-black mt-2">{rel.title}</h3>
                                  </div>
                                </Link>
                              )
                            }
                          </OwlCarousel>
                        </div>

                        :
                        ''
                    }
                  </div>
                  <div className="col-12 col-md-4 col-lg-3 rightp">
                    <div>
                      <h3>Most Popular Blogs</h3>
                      <div className="card MostBlogs">
                        <div className="card-body">
                          {
                            topblog.map((tops, tp) =>
                              <div key={tp}>
                                <div className="img">
                                  {
                                    helper.checkimagetype(tops.image) ?
                                      <picture>
                                        <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'blog/' + tops.image} alt={tops.title} width="200" height="200" />
                                      </picture>
                                      :
                                      <picture>
                                        <source srcSet={process.env.REACT_APP_API_IMAGEURL + 'blog/webp/' + tops.image + '.webp'} type="image/webp" />
                                        <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'blog/jpg/' + tops.image + '.jpg'} alt={tops.title} width="200" height="200" />
                                      </picture>
                                  }

                                </div>
                                <div className="text">
                                  <h3><Link to={"/blog/" + tops.alias}>{tops.title}</Link></h3>
                                  <div className="d-flex"><a href="/#"><i className="fal fa-user-circle"></i> Admin</a> | <span><i className="far fa-calendar-alt"></i> {moment(tops.post_date).format('DD MMM YYYY')}</span></div>
                                </div>
                              </div>
                            )
                          }
                        </div>
                      </div>
                      <h3>Newsletter</h3>
                      <div className="card Newsletter">
                        <form onSubmit={SubscribeNewsletter} className="card-body">
                          <div className="form-group">
                            <label htmlFor="name" className="form-label">Email Address*</label>
                            <input type="text" className="form-control" id="name" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email " />
                            {emailerror ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{emailerror}</span> : ''}
                          </div>
                          <div className="text-center">
                            {
                              btnloader ?
                                <button type="button" className="btn btn-thm btn-sm mt-3 disabled"><span className="me-3">Loading...</span></button>
                                :
                                <button type="submit" className="btn btn-thm btn-sm mt-3"><span className="me-3">Subscribe</span> <img src={require("../asset/img/btn-arrow.svg").default} width="30" height="30" alt="Arrow" /></button>
                            }
                          </div>
                        </form>
                      </div>
                      {
                        categories.length ?
                          <>
                            <h3>Categories</h3>
                            <div className="card MostBlogs">
                              <div className="card-body Archives">
                                <ul className="list-group list-group-flush">
                                  {
                                    categories.map((category, ct) =>
                                      <li key={ct} className="list-group-item">
                                        <Link to={"/blog?listtype=catgeory&category=" + category.alias}><i className="fal fa-arrow-right me-2"></i> {category.title}</Link>
                                      </li>
                                    )
                                  }
                                </ul>
                              </div>
                            </div>
                          </>
                          :
                          ''
                      }
                    </div>
                  </div>
                </div>
              </div>
          }
        </section>
      </main>
      <ToastContainer />
    </>
  )
}

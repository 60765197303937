import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import moment from 'moment'
import helper from '../Helper';

import BlogLoader from '../loader/Blog';
import Meta from './Meta';
export default function Blog() {
    const location = useLocation();
    const [blogs, setBlogs] = useState([]);
    const [cmsdata, setBlogCms] = useState('');
    const [pageloader, setPageLoader] = useState(false);
    const fetchData = async () => {
        setPageLoader(true);
        const Api = process.env.REACT_APP_API + 'blogs' + location.search;
        const CmsApI = process.env.REACT_APP_API + 'getcmsdata/12';

        const ApiResponse = await axios.get(Api);
        const CmsApIResponse = await axios.get(CmsApI);

        await axios.all([ApiResponse,CmsApIResponse])
            .then((res) => {
                if (res[0]) {
                    setBlogs(res[0].data.result.data);
                    setPageLoader(false);
                }
                if (res[1]) {
                    setBlogCms(res[1].data.result);
                }
            });
    }
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [location])

    return (
        <>
            <Meta id={6}/>
            <main>
                <section className="inner-banner Ibanner">
                    <div className="bgimg">
                        <picture>
                            <source srcSet={require('../asset/img/banner-bg1.webp')} type="image/webp" />
                            <img loading="lazy" src={require('../asset/img/banner-bg1.jpg')} alt="slide1" height="452" width="452" />
                        </picture>
                    </div>
                    <div className="section">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    <ol className="breadcrumb justify-content-center">
                                        <li className="breadcrumb-item"><NavLink to="/"><img src={require('../asset/img/home-w.svg').default} width="14" height="14" alt="Home" /></NavLink></li>
                                        {
                                            location.search?
                                                <>
                                                    <li className="breadcrumb-item"><NavLink to='/blog' aria-current="page">Blog </NavLink></li>
                                                    <li className="breadcrumb-item"><a href='/#' style={{cursor: 'pointer', pointerEvents: 'none'}} aria-current="page">Blog Category</a></li>
                                                </>
                                                :
                                                <li className="breadcrumb-item"><a href='/#' style={{cursor: 'pointer', pointerEvents: 'none'}} aria-current="page">Blog</a></li>
                                        }
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="Sec6">
                    {
                        pageloader ?
                            <BlogLoader/>
                            :

                            <div className="container">
                                 <div className="row align-items-center justify-content-center">
                                    <div className="col-12 col-md-9 col-lg-8 text-center wow fadeInUp">
                                        <span>{cmsdata.title ?? 'Purple Unicorn'}</span>
                                        <h2 className="Heading h1 fs-xl mb-4">{cmsdata.heading ?? 'Our Blogs'}</h2>
                                        <div dangerouslySetInnerHTML={{__html:cmsdata.description ?? ''}}/>
                                    </div>
                                </div>
                                {
                                    blogs.length ?

                                        <div className="row blog">
                                            {
                                                blogs.map((blog, b) =>

                                                    <div key={b} className="col-xxl-3 col-md-4 col-ms-6 mb-4">
                                                        <Link to={'/blog/' + blog.alias} className="card">
                                                            <div className="card-header">
                                                                {
                                                                    helper.checkimagetype(blog.image) ?
                                                                        <picture>
                                                                            <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'blog/' + blog.image} alt={blog.title} width="121" height="131" />
                                                                        </picture>
                                                                        :
                                                                        <picture>
                                                                            <source srcSet={process.env.REACT_APP_API_IMAGEURL + 'blog/webp/' + blog.image + '.webp'} type="image/webp" />
                                                                            <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'blog/jpg/' + blog.image + '.jpg'} alt={blog.title} width="121" height="131" />
                                                                        </picture>

                                                                }

                                                            </div>
                                                            <div className="card-body mt-1 text-center">
                                                                <span className="badge bgthm">{moment(blog.post_date).format('DD MMMM YYYY')}</span>
                                                                <h2 className="h5 mt-2">{blog.title}</h2>
                                                            </div>
                                                        </Link>
                                                    </div>

                                                )
                                            }

                                        </div>
                                        :
                                        <div className='text-center'>
                                            <h5 className='mt-5 text-secondary'>DATA NOT FOUND</h5>
                                            <p className='mb-0 text-secondary'>This page data you are looking for doesn`t exists or an other error occurred.</p>
                                            <p className='text-secondary'><Link to="/blog">Go Back To Blog</Link>, or head over to website to choose a new direction.</p>
                                        </div>
                                }
                            </div>
                    }
                </section>
            </main>
        </>
    )
}

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {Helmet} from "react-helmet";

export default function Meta(props) {
    const [meta,setMeta] = useState([]);
     const fetchMeta = async () => {
        let id = props.id;
        await axios.get(process.env.REACT_APP_API+'meta/'+id).then((responose)=>{
            setMeta(responose.data.result);
        });
    }
    useEffect(()=>{        
        fetchMeta();
        // eslint-disable-next-line
    },[]);

  return (
    <Helmet>
        <title>{meta ? meta.meta_title : 'Awarded Wedding Planner and Event Manamement Company in Ranchi, Jharkhand'}</title>
        <meta name="description" content={meta ? meta.meta_description : 'Purple Unicorn is a awarded top Wedding Planner Company in Ranchi, Jharkhand with an excellent reputation in the market for delivering professional Wedding planning and Event services at affordable rates.'} />
        <meta name="keywords" content={meta ? meta.meta_keywords : 'Awarded Wedding Planner and Event Manamement Company in Ranchi, Jharkhand'} />
    </Helmet>
  )
}

import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import helper from '../Helper';

import GalleryLoader from '../loader/Gallery';
import Meta from './Meta';

export default function Photos() {
    const [photos, setPhoto] = useState([]);
    const [pageloader, setPageLoader] = useState(true);
    const fetchData = async () => {
        const API = process.env.REACT_APP_API + 'album';
        const ApiResponse = await axios.get(API);

        await axios.all([ApiResponse])
            .then((res) => {
                if (res[0]) { setPhoto(res[0].data.result.data); setPageLoader(false); }
            });
    }
    useEffect(() => {
        fetchData();
    }, [])
    return (
        <>
        <Meta id={4}/>
            <main>
                <section className="inner-banner Ibanner">
                    <div className="bgimg">
                        <picture>
                            <source srcSet={require('../asset/img/banner-bg1.webp')} type="image/webp" />
                            <img loading="lazy" src={require('../asset/img/banner-bg1.jpg')} alt="slide1" height="452" width="452" />
                        </picture>
                    </div>
                    <div className="section">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    <ol className="breadcrumb justify-content-center">
                                        <li className="breadcrumb-item"><NavLink to="/"><img src={require('../asset/img/home-w.svg').default} width="14" height="14" alt="Home" /></NavLink></li>
                                        <li className="breadcrumb-item"><a href='/#' style={{cursor: 'pointer', pointerEvents: 'none'}} aria-current="page">Photos</a></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    pageloader ?
                        <GalleryLoader/>
                        :
                        <section className="Sec5" data-text={pageloader ? '' : "Gallery"}>
                            <div className="container">
                                <div className="row align-items-center justify-content-center">
                                    <div className="col-12 col-md-9 col-lg-8 text-center wow fadeInUp">
                                        <h1 className="Heading h1 fs-xl mb-4">Our Event Photos</h1>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    {
                                        photos.map((photo, p) =>
                                            <div key={p} className="col-6 col-md-4 col-lg-3 mb-4 wow fadeInUp">
                                                <Link to={"/gallery/"+photo.alias} className="ImgBox">
                                                    {
                                                        helper.checkimagetype(photo.image) ?
                                                            <picture>
                                                                <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'album/' + photo.image} alt={photo.title} width="1600" height="650" />
                                                            </picture>
                                                            :
                                                            <picture>
                                                                <source srcSet={process.env.REACT_APP_API_IMAGEURL + 'album/webp/' + photo.image+'.webp'} type="image/webp" />
                                                                <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'album/jpg/' + photo.image+'.jpg'} alt={photo.title} width="1600" height="650" />
                                                            </picture>
                                                    }

                                                </Link>
                                                <Link to={"/gallery/"+photo.alias}><h2 className="text-center m-0 mt-2 thm">{photo.title}</h2></Link>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </section>
                }
            </main>
        </>
    )
}

import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = (props) => (
    <div className='container text-center' style={{opacity:'.5'}}>
        <ContentLoader viewBox="0 0 600 100" backgroundColor="#ddd" foregroundColor="#a6b5ce">
            <rect x="200" y="50" rx="2" ry="2" width="200" height="21" /> 
            <rect x="250" y="30" rx="2" ry="2" width="100" height="11" />
        </ContentLoader>
        <ContentLoader viewBox="0 0 600 200" backgroundColor="#ddd" foregroundColor="#a6b5ce">
            <rect x="0" y="0" rx="2" ry="2" width="195" height="140" />
            <rect x="203" y="0" rx="2" ry="2" width="195" height="140" />
            <rect x="405" y="0" rx="2" ry="2" width="195" height="140" />
        </ContentLoader>
    </div>
)

export default MyLoader
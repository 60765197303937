import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = (props) => (
    <div className='container text-center' style={{opacity:'.5'}}>
        <ContentLoader viewBox="0 0 600 300" backgroundColor="#ddd" foregroundColor="#a6b5ce">
            <rect x="0" y="10" rx="2" ry="2" width="300" height="250" />
            <rect x="370" y="10" rx="2" ry="2" width="230" height="250" />
        </ContentLoader>
    </div>
)

export default MyLoader
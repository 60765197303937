import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import '../asset/cuscomcss/contact.css'
import axios from 'axios';
import ContactLoader from '../loader/Contact';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import Meta from './Meta';
export default function Contact() {
    const location = useLocation();
    const NameService = location.search ? location.search.split("=")[1].split('&')[0] : '';
    const IdService = location.search ? location.search.split("=")[2] : ''
    const [data, setData] = useState('');
    const [countries, setCountries] = useState([]);
    const [pageloader, setPageLoader] = useState(true);
    const [servicename, setServiceName] = useState(NameService);
    const [serviceid, setServiceId] = useState(IdService);

   
    const [btnloader, setBtnloader] = useState(false);
    const [formname, setFormName] = useState('');
    const [formemail, setFormEmail] = useState('');
    const [formphone, setFormPhone] = useState('');
    const [formsubject, setFormSubject] = useState('');
    const [formmessage, setFormMessage] = useState('');
    const [error, setError] = useState({ name: "", email: "", phone: "", message: "", companyname: "" });


    const fetchData = async () => {
        const AboutApI = process.env.REACT_APP_API + 'getcmsdata/4';
        const CountryApI = process.env.REACT_APP_API + 'countries';

        const AboutApiResponse = await axios.get(AboutApI);
        const CountryApIResponse = await axios.get(CountryApI);

        await axios.all([AboutApiResponse, CountryApIResponse])
            .then((response) => {
                if (response[0]) {
                    setData(response[0].data.result);
                    setPageLoader(false);
                }
                if (response[1]) {
                    setCountries(response[1].data.result);
                    setTimeout(() => {
                        $('.CountryCode .dropdown-menu').find('li').click(function (e) {
                            e.preventDefault();
                            var spa = $(this).data('text');
                            $('.CountryCode #CountryName').text(spa);
                        });
                    }, 2000);
                }
            });
    }

    useEffect(() => {
        if(location.search){ setFormSubject(servicename); }
        else{ 
            setFormSubject('');
            setServiceName('');
            setServiceId('');
         }
        fetchData();
        // eslint-disable-next-line
    }, [location]);

    function SearchCountryCode(countrysearch) {
        let val = countrysearch.toLowerCase();
        $(".countrylist li").each(function () {
            $(this).toggle($(this).text().toLowerCase().includes(val));
        });
    }

    async function ContactForm(e) {
        e.preventDefault();
        setBtnloader(true);
        setError({ name: "", email: "", phone: "", message: "", subject: "" });        
        let callback = { name: formname, email: formemail, phone: formphone, message: formmessage, subject: formsubject,serviceid:serviceid }
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        await axios.post(process.env.REACT_APP_API + 'contactform', callback, config)
            .then((response) => {
                toast.success(response.data.message);
                setBtnloader(false);
                setFormName("");
                setFormEmail("");
                setFormPhone("");
                if(!servicename){ 
                    setFormSubject(""); 
                }
                setFormMessage("");
            })
            .catch((errors) => {
                if (errors.response !== undefined) {
                    let rterror = errors.response.data.errors;
                    setError({
                        name: rterror.name,
                        email: rterror.email,
                        phone: rterror.phone,
                        message: rterror.message,
                        subject: rterror.subject
                    });
                    setBtnloader(false);
                }
            });

    }

    return (
        <>
            <Meta id={3}/>
            <main>
                <section className="inner-banner Ibanner">
                    <div className="bgimg">
                        <picture>
                            <source srcSet={require('../asset/img/banner-bg1.webp')} type="image/webp" />
                            <img loading="lazy" src={require('../asset/img/banner-bg1.jpg')} alt="slide1" height="452" width="452" />
                        </picture>
                    </div>
                    <div className="section">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    <ol className="breadcrumb justify-content-center">
                                        <li className="breadcrumb-item"><NavLink to="/"><img src={require('../asset/img/home-w.svg').default} width="14" height="14" alt="Home" /></NavLink></li>
                                        <li className="breadcrumb-item"><a href='/#' style={{cursor: 'pointer', pointerEvents: 'none'}} aria-current="page">Contact us</a></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="Home">
                    {
                        pageloader ?
                            <ContactLoader/>
                            :
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-lg-5 col-md-6 offset-md-1 order-md-last">
                                        <span className="SubTitle">{data.title ?? ''}</span>
                                        <h1 className="Heading h1">{data.heading ?? ''}</h1>
                                        <div dangerouslySetInnerHTML={{ __html: data.description }} />
                                    </div>
                                    <div className="col-12 col-lg-6 col-md-7">
                                        <form className="card" onSubmit={ContactForm}>
                                            <div className="card-body pt-3 p-4">
                                                <h3 className="h4 m-0 d-flex thm1 justify-content-between align-items-center w-100">Write to us</h3><hr className="border-secondary" />
                                                <div className="form-floating mb-4">
                                                    <input type="text" className="form-control" id="rfname" value={formname} onChange={(e) => { setFormName(e.target.value) }} placeholder="Name" />
                                                    <label htmlFor="rfname" className="form-label">Name</label>
                                                    {error.name ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{error.name}</span> : ''}
                                                </div>
                                                <div className="form-floating mb-4">
                                                    <input type="text" className="form-control" id="remail" value={formemail} onChange={(e) => { setFormEmail(e.target.value) }} placeholder="Email ID" />
                                                    <label htmlFor="remail" className="form-label">Email ID</label>
                                                    {error.email ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{error.email}</span> : ''}
                                                </div>
                                                <div className="d-flex CountryCode CrrencySelect1">
                                                    <span className="CountryCodeBox">
                                                        <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><span id="CountryName">+91</span></button>
                                                        <ul className="dropdown-menu countrylist">
                                                            <li className="d-block"><input type="search" className="form-control SearchConCode" onChange={(e) => { SearchCountryCode(e.target.value) }} placeholder="Search Country Code" /></li>
                                                            {
                                                                countries.map((country, ct) =>
                                                                    <li key={ct} data-code={country.shortname} data-name={country.name} data-text={'+' + country.phonecode}><i className="flagicon fi-af"></i>{country.name} <span>(+{country.phonecode})</span></li>

                                                                )
                                                            }
                                                        </ul>
                                                    </span>
                                                    <span className="form-floating w-100">
                                                        <input type="number" className="form-control" value={formphone} id="rconno" onChange={(e) => { setFormPhone(e.target.value) }} placeholder="Phone No." />
                                                        <label htmlFor="lemail" className="form-label">Phone No.</label>
                                                    </span>
                                                </div>
                                                {error.phone ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{error.phone}</span> : ''}

                                                <div className="form-floating mb-4 mt-4">
                                                    {
                                                        !servicename ?
                                                            <input type="text" className="form-control" id="subject" value={formsubject} onChange={(e) => { setFormSubject(e.target.value) }} placeholder="subject" />
                                                            :
                                                            <input type="text" className="form-control" id="subject" readOnly value={servicename} onChange={(e) => { setFormSubject(e.target.value) }} placeholder="subject" />

                                                    }
                                                    <label htmlFor="subject" className="form-label">Subject</label>
                                                    {error.subject ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{error.subject}</span> : ''}
                                                </div>
                                                <div className="form-floating">
                                                    <textarea className="form-control" id="Requirements" value={formmessage} onChange={(e) => { setFormMessage(e.target.value) }} placeholder="Your Requirements"></textarea>
                                                    <label htmlFor="Requirements" className="form-label">Your Requirements</label>
                                                    {error.message ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{error.message}</span> : ''}
                                                </div>
                                                <div className="text-center">
                                                    {
                                                        btnloader ?
                                                            <button type='button' className="btn btn-thm disabled"><span className="me-3"><i className='fab fa-spinner-third fa-spin'></i> Loading...</span></button>

                                                            :
                                                            <button className="btn btn-thm"><span className="me-3">Submit</span> <img src={require('../asset/img/btn-arrow.svg').default} width="30" height="30" alt="Arrow" /></button>

                                                    }
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                    }
                </section>
                {
                    !pageloader ?
                        <section className="Home" style={{ lineHeight: 0 }}>
                            <div dangerouslySetInnerHTML={{ __html: data.description_2 }} />
                        </section>
                        : ''
                }
            </main>
            <ToastContainer />
        </>
    )
}

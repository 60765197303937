import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, NavLink, useParams } from 'react-router-dom'
import helper from '../Helper';
import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

import GalleryLoader from '../loader/Gallery';

export default function Galleryinner() {
    Fancybox.bind('[data-fancybox="gallery"]', {});
    const params = useParams();
    const [galleries, setPhoto] = useState([]);
    const [album, setAlbum] = useState([]);
    const [pageloader, setPageLoader] = useState(true);
    const fetchData = async () => {
        const API = process.env.REACT_APP_API + 'gallery/' + params.alias + '?type=photos';
        const ApiResponse = await axios.get(API);

        const API2 = process.env.REACT_APP_API + 'album/' + params.alias;
        const Api2Response = await axios.get(API2);

        await axios.all([ApiResponse, Api2Response])
            .then((res) => {
                if (res[0]) { setPhoto(res[0].data.result.data); setPageLoader(false); }
                if (res[1]) { setAlbum(res[1].data.result.data[0]); }
            });
    }
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <main>
                <section className="inner-banner Ibanner">
                    <div className="bgimg">
                        <picture>
                            <source srcSet={require('../asset/img/banner-bg1.webp')} type="image/webp" />
                            <img loading="lazy" src={require('../asset/img/banner-bg1.jpg')} alt="slide1" height="452" width="452" />
                        </picture>
                    </div>
                    <div className="section">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    <ol className="breadcrumb justify-content-center">
                                        <li className="breadcrumb-item"><NavLink to="/"><img src={require('../asset/img/home-w.svg').default} width="14" height="14" alt="Home" /></NavLink></li>
                                        <li className="breadcrumb-item"><NavLink to="/photos">Photos</NavLink></li>
                                        <li className="breadcrumb-item"><a href='/#' style={{cursor: 'pointer', pointerEvents: 'none'}} aria-current="page">Photos Gallery</a></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                pageloader ?
                    <GalleryLoader/>
                    :
                        <section className="Sec5" data-text={pageloader ? '' : "Gallery"}>
                    
                            <div className="container">
                                {
                                    galleries.length ?

                                        <div className="row align-items-center justify-content-center">
                                            <div className="col-12 col-md-9 col-lg-8 text-center wow fadeInUp">
                                                <h1 className="Heading h1 fs-xl mb-4">{album.title ?? ''}</h1>
                                            </div>
                                        </div>
                                        :
                                        ''
                                }
                                {
                                    galleries.length ?

                                        <div className="row justify-content-center">
                                            {
                                                galleries.map((gallery, p) =>
                                                    <div className="col-6 col-md-4 col-lg-3 wow fadeInUp" key={p}>
                                                        {
                                                            helper.checkimagetype(gallery.image) ?
                                                                <a href={process.env.REACT_APP_API_IMAGEURL + 'album/gallery/' + gallery.image} className="ImgBox" data-fancybox="gallery">
                                                                    <picture>
                                                                        <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'album/gallery/' + gallery.image} alt={gallery.title} width="1600" height="650" />
                                                                    </picture>
                                                                </a>
                                                                :
                                                                <a href={process.env.REACT_APP_API_IMAGEURL + 'album/gallery/webp/' + gallery.image + '.webp'} className="ImgBox" data-fancybox="gallery">
                                                                    <picture>
                                                                        <source srcSet={process.env.REACT_APP_API_IMAGEURL + 'album/gallery/webp/' + gallery.image + '.webp'} type="image/webp" />
                                                                        <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'album/gallery/jpg/' + gallery.image + '.jpg'} alt={gallery.title} width="1600" height="650" />
                                                                    </picture>
                                                                </a>
                                                        }
                                                    </div>

                                                )
                                            }
                                        </div>
                                        :
                                        <div className='text-center'>
                                            <h5 className='mt-5 text-secondary'>DATA NOT FOUND</h5>
                                            <p className='mb-0 text-secondary'>This page data you are looking for doesn`t exists or an other error occurred.</p>
                                            <p className='text-secondary'><Link to="/">Go Back To Home</Link>, or head over to website to choose a new direction.</p>
                                        </div>
                                }
                            </div>
                        </section>
                }
            </main>
        </>
    )
}

import React from 'react'
import { Helmet } from 'react-helmet'
import { Link, NavLink } from 'react-router-dom'

export default function Page404() {
  return (
    <>
      <Helmet>
        <title>Page Not Found : </title>
        <meta name="description" content="Page Not Found : " />
        <meta name="keywords" content="Page Not Found : " />
      </Helmet>
      <main>
        <section className="inner-banner Ibanner">
          <div className="bgimg">
            <picture>
              <source srcSet={require('../asset/img/banner-bg1.webp')} type="image/webp" />
              <img loading="lazy" src={require('../asset/img/banner-bg1.jpg')} alt="slide1" height="452" width="452" />
            </picture>
          </div>
          <div className="section">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12">
                  <ol className="breadcrumb justify-content-center">
                    <li className="breadcrumb-item"><NavLink to="/"><img src={require('../asset/img/home-w.svg').default} width="14" height="14" alt="Home" /></NavLink></li>
                    <li className="breadcrumb-item"><a href='/#' style={{cursor: 'pointer', pointerEvents: 'none'}} aria-current="page">Page Not Found</a></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="p404">
          <div className="container">
            <div className="text-center">
              <img src="https://static.vecteezy.com/system/resources/thumbnails/006/549/647/small/404-landing-page-free-vector.jpg" alt="" width="494" height="200" />
              <h5 className='mt-5 text-secondary'>PAGE NOT FOUND</h5>
              <p className='mb-0 text-secondary'>This page you are looking for doesn`t exists or an other error occurred.</p>
              <p className='text-secondary'><Link to="/">Go Back To Home</Link>, or head over to website to choose a new direction.</p>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

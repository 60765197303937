import axios from 'axios';
import React, { useEffect, useState } from 'react'
import ContentLoader from 'react-content-loader';
import { NavLink } from 'react-router-dom'
import helper from '../Helper';
import Meta from './Meta';

export default function Faqs() {
    const [data, setData] = useState('');
    const [faqsdata, setFaqsData] = useState([]);
    const [pageloader, setPageLoader] = useState(true);

    const fetchData = async () => {
        const ApI = process.env.REACT_APP_API + 'getcmsdata/11';
        const ApI2 = process.env.REACT_APP_API + 'faqs';

        const ApiResponse = await axios.get(ApI);
        const Api2Response = await axios.get(ApI2);

        await axios.all([ApiResponse, Api2Response])
            .then((res) => {
                if (res[0]) { setData(res[0].data.result); }
                if (res[1]) { setFaqsData(res[1].data.result.data); }
                setPageLoader(false);
            })
    }

    useEffect(() => {
        fetchData();
    }, []);
    return (
        <>
            <Meta id={10}/>
            <main>
                <section className="inner-banner Ibanner">
                    <div className="bgimg">
                        <picture>
                            <source srcSet={require('../asset/img/banner-bg1.webp')} type="image/webp" />
                            <img loading="lazy" src={require('../asset/img/banner-bg1.jpg')} alt="slide1" height="452" width="452" />
                        </picture>
                    </div>
                    <div className="section">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    <ol className="breadcrumb justify-content-center">
                                        <li className="breadcrumb-item"><NavLink to="/"><img src={require('../asset/img/home-w.svg').default} width="14" height="14" alt="Home" /></NavLink></li>
                                        <li className="breadcrumb-item"><a href='/#' style={{cursor: 'pointer', pointerEvents: 'none'}} aria-current="page">Faqs</a></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="CmsPage">
                    {
                        pageloader ?
                            <div className='text-center'>
                                <ContentLoader
                                    viewBox="0 0 400 160"
                                    height={160}
                                    width={400}
                                    backgroundColor="transparent"
                                >
                                    <circle cx="150" cy="86" r="8" />
                                    <circle cx="194" cy="86" r="8" />
                                    <circle cx="238" cy="86" r="8" />
                                </ContentLoader>
                            </div>
                            :

                            <div className="container">
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <h1 className="text-center mb-4 h1">{helper.seprateHeading(data.title)[0]}<strong> {helper.seprateHeading(data.title)[1]} </strong></h1>
                                        <div dangerouslySetInnerHTML={{ __html: data.description }} />
                                    </div>
                                </div>
                                <div className="row mt-4 justify-content-center">
                                    <div className="col-md-11 col-lg-10">
                                        <div className="accordion accordion-flush Faqs" id="Faqs">
                                            {
                                                faqsdata.map((faqs, f) =>
                                                    <div className="accordion-item" key={f}>
                                                        <div className="accordion-header" id={"Pay1" + f}>
                                                            <button className={f === 0 ? "accordion-button" : "accordion-button collapsed"} type="button" data-bs-toggle="collapse" data-bs-target={"#Faqs1" + f} aria-expanded="true" aria-controls="Faqs1">{faqs.title}</button>
                                                        </div>
                                                        <div id={"Faqs1" + f} className={f === 0 ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby="Pay1" data-bs-parent="#Faqs">
                                                            <div className="accordion-body" dangerouslySetInnerHTML={{ __html: faqs.description }} />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </section>
            </main>
        </>
    )
}

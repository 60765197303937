import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = (props) => (
    <div className='container text-center' style={{opacity:'.5'}}>
        <ContentLoader viewBox="0 0 600 100" backgroundColor="#ddd" foregroundColor="#a6b5ce">
            <rect x="200" y="50" rx="2" ry="2" width="200" height="21" /> 
            <rect x="250" y="30" rx="2" ry="2" width="100" height="11" />
        </ContentLoader>
        <ContentLoader viewBox="0 0 600 300" backgroundColor="#ddd" foregroundColor="#a6b5ce">
            <circle cx="15" cy="110" r="12" />
            <circle cx="15" cy="150" r="12" />
            <circle cx="15" cy="190" r="12" />
            <rect x="375" y="10" rx="0" ry="0" width="225" height="225" />
            <rect x="0" y="10" rx="0" ry="0" width="350" height="7" />
            <rect x="0" y="30" rx="0" ry="0" width="350" height="7" />
            <rect x="0" y="50" rx="0" ry="0" width="350" height="7" />
            <rect x="0" y="70" rx="0" ry="0" width="300" height="7" />
            <rect x="40" y="100" rx="0" ry="0" width="290" height="7" />
            <rect x="40" y="115" rx="0" ry="0" width="290" height="8" />
            <rect x="40" y="140" rx="0" ry="0" width="290" height="7" />
            <rect x="40" y="155" rx="0" ry="0" width="290" height="8" />
            <rect x="40" y="180" rx="0" ry="0" width="290" height="7" />
            <rect x="40" y="195" rx="0" ry="0" width="290" height="8" />
            <rect x="0" y="225" rx="0" ry="0" width="350" height="7" />
        </ContentLoader>
    </div>
)

export default MyLoader
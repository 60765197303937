import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import helper from '../Helper';
import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import '../asset/cuscomcss/video.css';

import GalleryLoader from '../loader/Gallery';
import Meta from './Meta';
export default function Videos() {
    Fancybox.bind('[data-fancybox="video"]', {});
    const [videos, setVideos] = useState([]);
    const [pageloader, setPageLoader] = useState(true);
    const fetchData = async () => {
        const API = process.env.REACT_APP_API + 'gallery?type=videos';
        const ApiResponse = await axios.get(API);

        await axios.all([ApiResponse])
            .then((res) => {
                if (res[0]) { 
                    setVideos(res[0].data.result.data); 
                    setPageLoader(false); 
                    setTimeout(() => {
                        initYouTubeVideos();
                    }, 500);
                }
            });
    }  

    function labnolIframe(div) {
        var iframe = document.createElement('iframe');
        iframe.setAttribute(
            'src',
            'https://www.youtube.com/embed/' + div.dataset.id + '?autoplay=1&rel=0'
        );
        iframe.setAttribute('frameborder', '0');
        iframe.setAttribute('allowfullscreen', '1');
        iframe.setAttribute(
            'allow',
            'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
        );
        div.parentNode.replaceChild(iframe, div);
    }

    function initYouTubeVideos() {
        var playerElements = document.getElementsByClassName('youtube-player');
        for (var n = 0; n < playerElements.length; n++) {
            var videoId = playerElements[n].dataset.id;
            var div = document.createElement('div');
            div.setAttribute('data-id', videoId);
            var thumbNode = document.createElement('img');
            thumbNode.src = '//i.ytimg.com/vi/ID/hqdefault.jpg'.replace(
                'ID',
                videoId
        );
            thumbNode.alt = "Youtube Voideo";
            thumbNode.loading = "lazy";
            div.appendChild(thumbNode);
            var playButton = document.createElement('div');
            playButton.setAttribute('class', 'play');
            div.appendChild(playButton);
            div.onclick = function () {
                labnolIframe(this);
            };
            playerElements[n].appendChild(div);
        }
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    },[])

    return (
        <>
        <Meta id={5}/>
            <main>
                <section className="inner-banner Ibanner">
                    <div className="bgimg">
                        <picture>
                            <source srcSet={require('../asset/img/banner-bg1.webp')} type="image/webp" />
                            <img loading="lazy" src={require('../asset/img/banner-bg1.jpg')} alt="slide1" height="452" width="452" />
                        </picture>
                    </div>
                    <div className="section">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    <ol className="breadcrumb justify-content-center">
                                        <li className="breadcrumb-item"><NavLink to="/"><img src={require('../asset/img/home-w.svg').default} width="14" height="14" alt="Home" /></NavLink></li>
                                        <li className="breadcrumb-item"><a href='/#' style={{cursor: 'pointer', pointerEvents: 'none'}} aria-current="page">Videos</a></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    pageloader ?
                    <GalleryLoader/>
                        :
                        <section className="Sec5" data-text={pageloader ? '' : "Gallery"}>
                            <div className="container">
                                <div className="row align-items-center justify-content-center">
                                    <div className="col-12 col-md-9 col-lg-8 text-center wow fadeInUp">
                                        <h1 className="Heading h1 fs-xl mb-4">Our Event Video Gallery</h1>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    {
                                        videos.map((video, v) =>
                                            <div key={v} className="col-6 col-md-4 col-lg-3 mb-4 wow fadeInUp">
                                                <div className="ImgBox">
                                                    <div className="youtube-player" data-id={helper.youtubeid(video.youtube_url)}></div>
                                                    <a href={video.youtube_url} data-fancybox="video" className="playVideo"> </a>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </section>
                }
            </main>
        </>
    )
}

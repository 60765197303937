import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import '../asset/cuscomcss/testimonial.css'
import axios from 'axios';
import helper from '../Helper';
import CmsLoader from '../loader/Service';
import Meta from './Meta';
export default function Testimonial() {
    const [testimonialsdata, setData] = useState([]);
    const [pageloader, setPageLoader] = useState(true);

    const fetchData = async () => {
        const ApI = process.env.REACT_APP_API + 'testimonials';
        const ApiResponse = await axios.get(ApI);

        await axios.all([ApiResponse])
            .then((res) => {
                if (res[0]) { setData(res[0].data.result.data); }
                setPageLoader(false);
            })
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, []);

    return (
        <>
        <Meta id={11}/>
            <main>
                <section className="inner-banner Ibanner">
                    <div className="bgimg">
                        <picture>
                            <source srcSet={require('../asset/img/banner-bg1.webp')} type="image/webp" />
                            <img loading="lazy" src={require('../asset/img/banner-bg1.jpg')} alt="slide1" height="452" width="452" />
                        </picture>
                    </div>
                    <div className="section">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    <ol className="breadcrumb justify-content-center">
                                        <li className="breadcrumb-item"><NavLink to="/"><img src={require('../asset/img/home-w.svg').default} width="14" height="14" alt="Home" /></NavLink></li>
                                        <li className="breadcrumb-item"><a href='/#' style={{cursor: 'pointer', pointerEvents: 'none'}} aria-current="page">Testimonial</a></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="TestimonialSec" data-text={!pageloader ? "Client Reviews" : ''}>
                    {
                        pageloader ?
                            <CmsLoader/>
                            :
                            <div className="container">
                                <div className="row align-items-center justify-content-center">
                                    <div className="col-12 col-md-9 col-lg-8 text-center wow fadeInUp">
                                        <h1 className="Heading h1 fs-xl mb-4">Testimonial</h1>
                                    </div>
                                </div>
                                <div className="row TestM">
                                    {
                                        testimonialsdata.map((testimonial, t) =>
                                            <div key={t} className="col-md-4 col-sm-6 wdesign wow fadeInUp">
                                                <a className="card card-body p-0" href='/#'>
                                                    <div className="bubble">
                                                        <div className="text" dangerouslySetInnerHTML={{ __html: testimonial.description }} />
                                                    </div>
                                                    <div className="user d-flex w-75 mx-auto mb-5">
                                                        <div className="uimg me-3">
                                                            {
                                                                helper.checkimagetype(testimonial.image) ?
                                                                    <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'testimonial/' + testimonial.image} alt="User Name" width="50" height="50" />
                                                                    :
                                                                    <img loading="lazy" src={process.env.REACT_APP_API_IMAGEURL + 'testimonial/webp/' + testimonial.image + '.webp'} alt="User Name" width="50" height="50" />

                                                            }
                                                        </div>
                                                        <div className="uname">
                                                            <strong className="name d-block">{testimonial.title}</strong>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                    }
                </section>
            </main>
        </>
    )
}

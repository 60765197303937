import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import CmsLoader from '../loader/Cms1';
import Meta from './Meta';
export default function Privacy() {
    const [data, setData] = useState('');
    const [pageloader, setPageLoader] = useState(true);

    const fetchData = async () => {
        const ApI = process.env.REACT_APP_API + 'getcmsdata/8';
        const ApiResponse = await axios.get(ApI);
        await axios.all([ApiResponse])
            .then((response) => {
                if (response[0]) { setData(response[0].data.result); }
                setPageLoader(false);
            });
    }

    useEffect(() => {
        fetchData();
    }, []);
    return (
        <>
           <Meta id={8}/>
            <main>
                <section class="inner-banner Ibanner">
                    <div class="bgimg">
                        <picture>
                            <source srcSet={require('../asset/img/banner-bg1.webp')} type="image/webp" />
                            <img loading="lazy" src={require('../asset/img/banner-bg1.jpg')} alt="slide1" height="452" width="452" />
                        </picture>
                    </div>
                    <div class="section">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-12">
                                    <ol className="breadcrumb justify-content-center">
                                        <li className="breadcrumb-item"><NavLink to="/"><img src={require('../asset/img/home-w.svg').default} width="14" height="14" alt="Home" /></NavLink></li>
                                        <li className="breadcrumb-item"><a href='/#' style={{cursor: 'pointer', pointerEvents: 'none'}} aria-current="page">{data.title ?? ''}</a></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="Sec2 CmsPage bg-white">
                    {
                        pageloader ?
                        <CmsLoader/>
                            :
                            <div class="container">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <h1 class="Heading">{data.title ?? ''}</h1>
                                    </div>
                                    <div class="col-12">
                                        <div dangerouslySetInnerHTML={{__html:data.description}} />
                                    </div>
                                </div>
                            </div>
                    }
                </section>
            </main>
        </>
    )
}
